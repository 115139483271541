import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';

import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';

import {GetAnswered} from "../../Services/Questionnares/index";

import './AnswersCard.scss';

const AnswersCard = ({client}) => {
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const access_token = localStorage.getItem('access_token');
            const response = await GetAnswered(access_token, client.questionnaire[0].id);
            const answers = response?.data?.answereds;
            setAnswers(answers);
        };

        if (client.questionnaire && client.questionnaire.length > 0) {
            fetchData();
        }
    }, [client.questionnaire]);

    return (
        <Card
            variant="outlined"
            sx={{
                maxHeight: 350,
                overflowY: 'scroll',
                scrollbarColor: 'grey white',
            }}
        >
            <div className="calories-calculator">
                <header>
                    <div>
                        <Title
                            title={client.questionnaire.length > 0 ? `Anamnese - ${client.questionnaire[0].quizzes.name}` : 'Anamnese'}/>
                        <Subtitle subtitle="Respostas do aluno"/>
                    </div>
                </header>

                <div className="divider"/>

                <CardContent>
                    <Box>
                        {client.questionnaire && client.questionnaire.length > 0 && answers.length > 0 && (
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                {answers.map((q, i) => (
                                    <Box sx={{
                                        p: 1
                                    }}>
                                        <Typography variant='body1' fontWeight={600}
                                                    fontSize={16}>{i + 1}. {q.question}</Typography>
                                        <Typography variant='body2' fontSize={14}><b>R:</b> {q.answered}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                        {(!client.questionnaire || client.questionnaire.length === 0 || answers.length === 0) && (
                            <Typography>Aluno ainda não respondeu a anamnese.</Typography>
                        )}
                    </Box>
                </CardContent>
            </div>
        </Card>
    );
};

export default AnswersCard;