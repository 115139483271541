import { PostAsync, GetAsync, DeleteAsync } from '../api'

/**
 * Método responsável por criar um presets
*/
const CreatePresets = (name, type) => {
    return new Promise(async (resolve, reject) => {
        const data = {
            name,
            type
        }
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await PostAsync(`/presets`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por criar um presets
*/
const CreatePresetsPlan = (payload, type, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await PostAsync(`/presets/${type}/${id}`, config, payload);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}


/**
 * Método responsável por deletar um presets
*/
const DeletePreset = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await DeleteAsync(`/presets/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err?.response?.data);
        }
    });
}

/**
 * Método responsável por buscar um presets
*/
const GetPreset = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/presets/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar presets
*/
const GetPresets = (type) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/presets?type=${type}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

export {
    CreatePresets,
    DeletePreset,
    GetPreset,
    GetPresets,
    CreatePresetsPlan
};