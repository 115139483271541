import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';

import { GetPresetsStatistics, GetTrainingsStatistics } from "../../Services/Trainings/index";

import './VolumeChart.scss';

const VolumeChart = ({ client, presetId = null }) => {
    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let response;
            if (presetId) {
                response = await GetPresetsStatistics(presetId);
            } else {
                response = await GetTrainingsStatistics(client.plan.id);
            }
            setStatistics(response.data.map((d) => ({ ...d, name: presetId ? `` : `Semana ${d.week}` })));
        }
        fetchData();
    }, [client.plan.id, presetId]);

    return (
        <Card
            variant="outlined"
        >
            <div className="volume-chart">
                <header>
                    <div>
                        <Title title="Volume" />
                        <Subtitle subtitle="Total de séries" />
                    </div>
                </header>

                <div className="divider" />

                <CardContent
                    sx={{
                        mt: 2,
                    }}>
                    <Box>
                        <ResponsiveContainer width={"100%"} height={300}>
                            <BarChart data={statistics}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" name="week" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="groups.1" name="Biceps" stackId="a" fill="#8884d8" />
                                <Bar dataKey="groups.2" name="Triceps" stackId="a" fill="#82ca9d" />
                                <Bar dataKey="groups.3" name="Antebraço" stackId="a" fill="#3ac49c" />
                                <Bar dataKey="groups.7" name="Peitoral" stackId="a" fill="#db3d60" />
                                <Bar dataKey="groups.8" name="Ombros" stackId="a" fill="#c2c627" />
                                <Bar dataKey="groups.9" name="Costas" stackId="a" fill="#ffc107" />
                                <Bar dataKey="groups.4" name="Quadriceps" stackId="b" fill="#C7427B" />
                                <Bar dataKey="groups.5" name="Posterior de coxas" stackId="b" fill="#1E306E" />
                                <Bar dataKey="groups.6" name="Glúteos" stackId="b" fill="#17a2b8" />
                                <Bar dataKey="groups.10" name="Trapézio" stackId="b" fill="#9fb0ea" />
                                <Bar dataKey="groups.11" name="Panturrilhas" stackId="b" fill="#0b032a" />
                                <Bar dataKey="groups.12" name="Abdômen" stackId="b" fill="#4c0615" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </CardContent>
            </div>
        </Card>
    );
};

export default VolumeChart;