import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import { CommonLoading } from 'react-loadingg';

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import Evolution from "../../Components/Evolution/Evolution";
import StudentHeader from '../../Components/StudentHeader/StudentHeader';
import DietInfo from './Components/DietInfo/DietInfo';
import MealsList from './Components/MealsList/MealsList';
import Header from '../../Components/Header/Header';

import { ListMealsDiet } from "../../Services/Diets/index";

import './DietDetails.scss';

const DietDetails = () => {
    const location = useLocation();
    const { diet, client, presetId } = location.state;
    const access_token = localStorage.getItem('access_token');

    const [meals, setMeals] = useState([]);
    const [replenishmentMeals, setReplenishmentMeals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const mealsParsed = [];
            const replenishmentMealsParsed = [];
            const diets = await ListMealsDiet(access_token, diet.id);

            diets.data?.forEach(row => {
                if (row.reposition) {
                    replenishmentMealsParsed.push(row);
                } else {
                    mealsParsed.push(row);
                }
            });
            setMeals(mealsParsed);
            setReplenishmentMeals(replenishmentMealsParsed);
            setLoading(false);
        };
        fetchData();
    }, [access_token, diet.id]);

    return (
        <div className="diet-details">
            <PageWrapper menuItem={client.presetId ? "/preset/diet" : "/manage-students"}>
                <div className="content">
                    {!client.presetId &&
                        <StudentHeader client={client} tab="2" />
                    }
                    {client.presetId &&
                        <Header
                            title="SmartNutri"
                            subtitle="Crie sua biblioteca de dietas"
                            showReturn
                        />
                    }

                    <Stack className='stack-container' spacing={2}>
                        <DietInfo
                            dietId={diet.id}
                            name={diet.name}
                            dietStart={diet.dietStart}
                            dietEnd={diet.dietEnd}
                            clientId={client.id}
                            planActiveId={client.plan.id}
                            presetId={presetId}
                        />
                        {loading && (<CommonLoading color="#553872" size="large" />)}
                        {!loading && (
                            <MealsList
                                meals={meals}
                                setMeals={setMeals}
                                replenishmentMeals={replenishmentMeals}
                                setReplenishmentMeals={setReplenishmentMeals}
                                diet={diet}
                                clientId={client.id}
                            />
                        )}
                    </Stack>
                </div>
                <Evolution client={client} />
            </PageWrapper>
        </div>
    );
};

export default DietDetails;