import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import {Box, Grid, Paper, Stack,} from "@mui/material";


import "./MessageBubble.scss";

const MessageBubble = ({message, userInfo, showAvatar}) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const isMe = message.sender.id === consultant.userId;

    const getTimestamp = (date) => {
        const createdAt = new Date(date);
        return `${createdAt.toLocaleTimeString()} - ${createdAt.toLocaleDateString()}`;
    };

    const getAvatar = (who, imagePath) => {
        return <Grid item xs={1} lg={1} mr={1}>
            <Stack
                spacing={0}
                alignItems="center"
                justifyContent="center"
            >
                <Avatar
                    src={imagePath !== null ? `${process.env.REACT_APP_S3_BUCKET_URL}/${imagePath}` : ""}
                    sx={{width: 52, height: 52}}/>
                <Typography variant="caption" sx={{marginTop: "3px"}} textAlign="center">
                    {who}
                </Typography>
            </Stack>
        </Grid>;
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignSelf: isMe ? "flex-end" : "flex-start",
                marginTop: "5px",
                paddingLeft: !showAvatar && !isMe ? 0 : "25px",
                paddingRight: !showAvatar && isMe ? 0 : "20px",
            }}
            key={message.id}
        >
            {!isMe && showAvatar && getAvatar(userInfo?.name, userInfo.profileImagePath)}

            <Grid item
                  xs={10}
                  lg={11}
                  sx={{
                      marginLeft: 2,
                      marginRight: 3,
                      marginBottom: 1
                  }}
            >
                <Stack>
                    <Paper sx={{
                        display: "flex",
                        alignSelf: isMe ? "flex-end" : "flex-start",
                        bgcolor: isMe ? "#1e306e" : "",
                        color: isMe ? "#FFF" : "",
                        borderBottomRightRadius: isMe ? 0 : 8,
                        borderBottomLeftRadius: isMe ? 8 : 0
                    }}>
                        <Typography
                            width="inherit"
                            textAlign={isMe ? "end" : "start"}
                            sx={{padding: "0.9rem", wordWrap: "break-word", textAlign: "start"}}
                        >
                            {message.content}
                        </Typography>
                    </Paper>
                    <Typography
                        variant="caption"
                        sx={{
                            display: "flex",
                            alignSelf: isMe ? "flex-end" : "flex-start",
                            marginTop: "3px"
                        }}
                    > {getTimestamp(message.createdAt)}
                    </Typography>
                </Stack>
            </Grid>

            {isMe && showAvatar && getAvatar('Você', consultant.profileImagePath)}
        </Box>
    );
};

export default MessageBubble;
