import React, { useContext, useEffect, useState } from 'react';
import { CommonLoading } from 'react-loadingg';

import Header from '../../Components/Header/Header';
import Calendar from './Components/Calendar/Calendar';
import Chart from './Components/Chart/Chart';
import Cards from './Components/Cards/Cards';
import GeneralInfo from './Components/GeneralInfo/GeneralInfo';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';

import { AppContext } from "../../Helpers/Context";

import { GetDashboardInfo } from '../../Services/Dashboard';

import './Home.scss';

const Home = () => {
    const date = new Date();
    const currentYear = date.getFullYear();

    const [height] = useState(400);
    const [dashboardInfo, setDashboardInfo] = useState();
    const [loading, setLoading] = useState(true);

    const access_token = localStorage.getItem('access_token');
    const { signOut } = useContext(AppContext);

    useEffect(() => {
        window.dataLayer.push({
            'event': 'page_view',
            'page_name': 'dashboard',
            'user_type': 'consultant'
        });
    }, []);

    useEffect(() => {
        const fetchDashboardInfo = async () => {
            const consultant = JSON.parse(sessionStorage.getItem('consultant'));
            const response = await GetDashboardInfo(access_token, consultant.id);
            setDashboardInfo(response.data);
            setLoading(false);

            window.dataLayer.push({
                'event': 'dashboard_loaded',
                'total_students': response.data.totalStudents,
                'monthly_new_students': response.data.monthlyNewStudents,
                'inactive_students': response.data.inactives
            });
        };

        const consultantStorage = sessionStorage.getItem('consultant');
        if (!access_token || !consultantStorage) {
            signOut();
        } else {
            fetchDashboardInfo();
        }
    }, [access_token, signOut]);

    return (
        <div className="home">
            <PageWrapper menuItem="/">
                <div className="content">
                    <Header
                        title="Dashboard Inicial"
                        subtitle="Últimos relatórios sobre os seus protocolos e alunos"
                    />
                    {!loading && (
                        <div className="home-body">
                            <Cards
                                totalStudents={dashboardInfo.totalStudents}
                                newStudents={dashboardInfo.monthlyNewStudents}
                                inactives={dashboardInfo.inactives}
                            />
                            <Chart
                                financialReport={dashboardInfo.financialReport}
                                height={height}
                            />
                            <GeneralInfo
                                currentYear={currentYear}
                                revenue={dashboardInfo.revenue}
                                plansInfo={dashboardInfo.plansInfo}
                            />
                        </div>
                    )}
                    {loading && (<CommonLoading color="#553872" size="large" />)}
                </div>

                <Calendar />
            </PageWrapper>
        </div>
    );
};

export default Home;