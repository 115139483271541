import React, { useEffect, useMemo, useState } from 'react';
import IntlCurrencyInput from "react-intl-currency-input";
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import { GetQuestionnares } from "../../../../Services/Questionnares";
// import { CreatePlans } from "../../../../Services/Plans";
import PlanPreview from './components/PlanPreview/PlanPreview';

const Planos = ({ currentSubStep, onDataChange }) => {
    const { id } = JSON.parse(sessionStorage.getItem('consultant'));
    const access_token = localStorage.getItem('access_token');

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    const periodData = useMemo(() => [
        { label: "Mensal (4 semanas)", id: 4 },
        { label: "Bimestral (9 semanas)", id: 9 },
        { label: "Trimestral (13 semanas)", id: 13 },
        { label: "Quadrimestral (17 semanas)", id: 17 },
        { label: "Pentamestral (21 semanas)", id: 21 },
        { label: "Semestral (26 semanas)", id: 26 },
        { label: "Anual (51 semanas)", id: 51 }
    ], []);

    const [period, setPeriod] = useState(periodData[0]);
    const savedFormValues = sessionStorage.getItem('planos_form_values');
    const initialFormValues = savedFormValues ? JSON.parse(savedFormValues) : {
        name: '',
        description: '',
        duration: periodData[0],
        price: 50,
        activities: {
            training: true,
            diet: true,
            inLoco: false
        },
        questionario: null
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [selectedOption, setSelectedOption] = useState();
    const [questionnaires, setQuestionnaires] = useState([]);
    const [createdPlan] = useState(null);

    const handleFormChange = (field, value) => {
        const newValues = {
            ...formValues,
            [field]: value
        };
        setFormValues(newValues);
        sessionStorage.setItem('planos_form_values', JSON.stringify(newValues));
        onDataChange(newValues, true);
    };

    const handlePriceChange = (event, value) => {
        const newValues = {
            ...formValues,
            price: value
        };
        setFormValues(newValues);
        onDataChange(newValues, true);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const questionnaireList = await GetQuestionnares(access_token, id);
            setQuestionnaires(questionnaireList?.data?.rows || []);
            if (questionnaireList?.data?.rows?.length > 0) {
                setSelectedOption(questionnaireList.data.rows[0].id);
            }
        };
        fetchData();
    }, [access_token, id]);

    useEffect(() => {
        if (questionnaires?.length > 0 && !formValues.questionario) {
            handleFormChange('questionario', questionnaires[0].id);
        }
    }, [questionnaires]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActivityChange = (activity, checked) => {
        const newValues = {
            ...formValues,
            activities: {
                ...formValues.activities,
                [activity]: checked
            }
        };
        setFormValues(newValues);
        onDataChange(newValues, true);
    };

    const renderStepContent = () => {
        switch (currentSubStep) {
            case 0:
                return (
                    <TextField
                        label="Nome do Plano"
                        fullWidth
                        value={formValues.name}
                        onChange={(e) => handleFormChange('name', e.target.value)}
                    />
                );
            case 1:
                return (
                    <TextField
                        label="Descrição"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        value={formValues.description}
                        onChange={(e) => handleFormChange('description', e.target.value)}
                        placeholder="(Ex: Montarei seu plano de alimentação e treinos baseado em suas necessidades com foco no emagrecimento)"
                        multiline
                    />
                );
            case 2:
                return (
                    <Autocomplete
                        disableClearable
                        options={periodData}
                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                        value={period}
                        onChange={(_, data) => {
                            setPeriod(data);
                            handleFormChange('duration', data.id);
                        }}
                        defaultValue={periodData[0]}
                    />
                );
            case 3:
                return (
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{
                            '& .price-container': {
                                position: 'relative',
                                width: '100%',
                                '& label': {
                                    position: 'absolute',
                                    top: -8,
                                    left: 14,
                                    backgroundColor: '#fff',
                                    padding: '0 4px',
                                    fontSize: '12px',
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    zIndex: 1
                                },
                                '& input': {
                                    width: '100%',
                                    padding: '16.5px 14px',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                    fontSize: '16px',
                                    outline: 'none',
                                    transition: 'border-color 0.2s',
                                    '&:hover': {
                                        borderColor: 'rgba(0, 0, 0, 0.87)'
                                    },
                                    '&:focus': {
                                        borderColor: '#662d91',
                                        borderWidth: '2px'
                                    }
                                }
                            }
                        }}>
                            <div className="price-container">
                                <label>Valor</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={handlePriceChange}
                                    defaultValue={formValues.price}
                                />
                            </div>
                        </Box>
                    </Box>
                );
            case 4:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            checked={formValues.activities.training}
                            onChange={(e) => handleActivityChange('training', e.target.checked)}
                            label="Treinos"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            checked={formValues.activities.diet}
                            onChange={(e) => handleActivityChange('diet', e.target.checked)}
                            label="Dieta"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            checked={formValues.activities.inLoco}
                            onChange={(e) => handleActivityChange('inLoco', e.target.checked)}
                            label="Treino Presencial"
                        />
                    </Box>
                );
            case 5:
                return (
                    <Box>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Questionário</InputLabel>
                            <Select
                                value={selectedOption || (questionnaires[0]?.id || '')}
                                onChange={handleSelectChange}
                                label="Questionário"
                            >
                                {questionnaires.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Box sx={{ mt: 2 }}>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{
                                        display: 'block',
                                        backgroundColor: 'rgba(102, 45, 145, 0.05)',
                                        padding: 2,
                                        borderRadius: 1,
                                        border: '1px solid rgba(102, 45, 145, 0.1)'
                                    }}
                                >
                                    Encontramos apenas um questionário cadastrado, mas não se preocupe,
                                    você pode criar um questionário personalizado depois.
                                </Typography>
                            </Box>
                        </FormControl>
                    </Box>
                );
            case 6:
                return createdPlan ? (
                    <PlanPreview planData={createdPlan} />
                ) : (
                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                        <Typography color="text.secondary">
                            Clique em Finalizar para criar seu plano
                        </Typography>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto', p: 2 }}>
            {renderStepContent()}
        </Box>
    );
};

export default Planos;