import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Tooltip,
    Typography
} from '@mui/material';

import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';

import './CaloriesCalculator.scss';

const CaloriesCalculator = ({ client, totalCalories, protein, carbohydrates, lipids }) => {
    const weightStrg = client ? Number(sessionStorage.getItem(`${client.id}_weight`) ?? 70) : sessionStorage.getItem('preset_weight') ?? 70;
    const heightStrg = client ? Number(sessionStorage.getItem(`${client.id}_height`) ?? client.height ?? sessionStorage.getItem('preset_height') ?? 170) : 170;

    const [gender, setGender] = useState(client ? client.gender : sessionStorage.getItem('preset_gender') ?? 'M');
    const [tmb, setTmb] = useState(0);
    const [weight, setWeight] = useState(weightStrg);
    const [height, setHeight] = useState(heightStrg);
    const [factor, setFactor] = useState(client ? (sessionStorage.getItem(`${client.id}_factor`) ?? 1) : sessionStorage.getItem('preset_factor') ?? 1);
    const [get, setGet] = useState(0);
    const [age, setAge] = useState(client ? client.age : sessionStorage.getItem('preset_age') ?? 25);

    useEffect(() => {
        const calcTmb = () => {
            let calc = 0;
            if (gender === 'M') {
                calc = 88.362 + (13.397 * weight) + (4.799 * height) - (5.677 * age);
            } else {
                calc = 447.593 + (9.247 * weight) + (3.098 * height) - (4.330 * age);
            }
            setTmb(parseInt(calc));

            if (client) {
                sessionStorage.setItem(`${client.id}_weight`, weight);
                sessionStorage.setItem(`${client.id}_height`, height);
                sessionStorage.setItem(`${client.id}_factor`, factor);
            } else {
                sessionStorage.setItem(`preset_weight`, weight);
                sessionStorage.setItem(`preset_height`, height);
                sessionStorage.setItem(`preset_age`, age);
                sessionStorage.setItem(`preset_gender`, gender);
                sessionStorage.setItem(`preset_factor`, factor);
            }
        };
        calcTmb();
    }, [weight, height, gender, age, client, factor]);

    useEffect(() => {
        setGet(tmb * factor);
    }, [tmb, factor]);

    return (
        <Card
            variant="outlined"
        >
            <div className="calories-calculator">
                <header>
                    <div>
                        <Title title="Calculadora" />
                        <Subtitle subtitle="Calcule o gasto energético" />
                    </div>
                </header>

                <div className="divider" />

                <CardContent
                    sx={{
                        mt: 2,
                    }}>
                    <Box>
                        <Select
                            name="formula"
                            defaultValue={1}
                            label="Formula"
                            fullWidth
                            placeholder="Formula"
                        >
                            <MenuItem value={1}>Harris & Benedict</MenuItem>
                        </Select>
                        <Box sx={{ mt: 1 }}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="radio-buttons-group-label"
                                    defaultValue="M"
                                    name="radio-buttons-group"
                                    value={gender}
                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                                    <FormControlLabel value="F" control={<Radio />} label="Feminino" />
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="weight">Peso (kg)</InputLabel>
                                <Input
                                    type="number"
                                    id="weight"
                                    placeholder="peso"
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                />
                            </FormControl>
                            <FormControl margin="normal">
                                <InputLabel htmlFor="height">Altura (cm)</InputLabel>
                                <Input
                                    type="number"
                                    id="height"
                                    placeholder="altura"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                />
                            </FormControl>
                            {!client && (
                                <FormControl margin="normal">
                                    <InputLabel htmlFor="age">Idade</InputLabel>
                                    <Input
                                        type="number"
                                        id="age"
                                        placeholder="idade"
                                        value={age}
                                        onChange={(e) => setAge(e.target.value)}
                                    />
                                </FormControl>
                            )}

                            <Tooltip title={<List>
                                <ListItem>Sedentário (pouca ou nenhuma atividade física) ≈ 1.2</ListItem>
                                <ListItem>Levemente ativo (exercício leve 1-3 dias por semana) ≈ 1.375</ListItem>
                                <ListItem>Moderadamente ativo (exercício moderado 3-5 dias por semana) ≈ 1.55</ListItem>
                                <ListItem>Altamente ativo (exercício intenso 6-7 dias por semana) ≈ 1.725</ListItem>
                                <ListItem>Extremamente ativo (exercício físico muito intenso/dupla jornada) ≈
                                    1.9</ListItem>
                            </List>} arrow>
                                <FormControl margin="normal">
                                    <InputLabel htmlFor="factor">Fator de atividade</InputLabel>
                                    <Input
                                        type="number"
                                        id="factor"
                                        placeholder="fator de atividade"
                                        value={factor}
                                        inputProps={{ step: 0.1 }}
                                        onChange={(e) => setFactor(e.target.value)}
                                    />
                                </FormControl>
                            </Tooltip>

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 2,
                            px: 2,
                            borderWidth: 0,
                            mt: 2,
                            borderStyle: 'solid',
                            borderColor: '#e5e7eb',
                            bgcolor: 'rgb(59 130 246 / 0.1)'
                        }}>
                            <Typography variant="body2" fontWeight={700}>Taxa metabólica basal (TMB)</Typography>
                            <Typography variant="body2" fontWeight={700}>{tmb}kcal</Typography>
                        </Box>
                        <Divider />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 2,
                            px: 2,
                            borderWidth: 0,
                            borderStyle: 'solid',
                            borderColor: '#e5e7eb',
                            bgcolor: 'rgb(59 130 246 / 0.1)'
                        }}>
                            <Typography variant="body2" fontWeight={700}>Gasto energético total (GET)</Typography>
                            <Typography variant="body2" fontWeight={700}>{get.toFixed(0)}kcal</Typography>
                        </Box>
                        <Divider />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            py: 2,
                            px: 2,
                            borderWidth: 0,
                            borderStyle: 'solid',
                        }}>
                            <Typography variant="body2" fontWeight={700}
                                color={get - totalCalories > 0 ? "#3ac49c" : "red"}>
                                Saldo Calórico
                                ({get - totalCalories > 0 ? 'Déficit' : 'Superávit'})
                            </Typography>
                            <Typography variant="body2" fontWeight={700}
                                color={get - totalCalories > 0 ? "#3ac49c" : "red"}>
                                {(get - totalCalories).toFixed(0)}kcal
                            </Typography>
                        </Box>
                        <Box className="subtitle"
                            sx={{ display: 'flex', gap: 1, mt: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={`${(protein / weight).toFixed(2)} g/kg PTN`} variant="outlined" color="success"
                                size="small" />
                            <Chip label={`${(carbohydrates / weight).toFixed(0)} g/kg CHO`} variant="outlined"
                                color="info" size="small" />
                            <Chip label={`${(lipids / weight).toFixed(2)} g/kg LIP`} variant="outlined" color="warning"
                                size="small" />
                        </Box>
                    </Box>
                </CardContent>
            </div>
        </Card>
    );
};

export default CaloriesCalculator;