/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    IconButton,
    Stack,
    Tab,
    Typography
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CommonLoading } from 'react-loadingg';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import ModalDelete from "../../Components/ModalDelete/ModalDelete";
import Evolution from "../../Components/Evolution/Evolution";
import EmptyState from "../../Components/EmptyState/EmptyState";
import StudentHeader from "../../Components/StudentHeader/StudentHeader";
import AnswersCard from "../../Components/AnswersCard/AnswersCard";

import { GetTrainings, RemoveTraining } from "../../Services/Trainings/index";
import { GetDiets, RemoveDiet } from "../../Services/Diets/index";

import ModalAddDiet from "./Components/ModalAddDiet/ModalAddDiet";
import ModalAddTraining from "./Components/ModalAddTraining/ModalAddTraining";
import CaloriesCalculator from "../../Components/CaloriesCalculator/CaloriesCalculator";
import ModalAddSchedule from "../Home/Components/Calendar/ModalAddSchedule/ModalAddSchedule";
import VolumeChart from "../../Components/VolumeChart/VolumeChart";
import ModalAddPreset from "./Components/ModalAddPreset/ModalAddPreset";
import TutorialOverlay from "../../Components/TutorialOverlay/TutorialOverlay";

import "./ManageClientPlan.scss";

const ManageClientPlan = () => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;
    const access_token = localStorage.getItem('access_token');

    const location = useLocation();
    const navigate = useNavigate();

    const { client } = location.state;
    const isTutorial = location.search.includes('tutorial=true');
    const expiredPlan = new Date(client.plan.dueDate) < new Date();
    const prevTab = sessionStorage.getItem('prevTab');

    const [tab, setTab] = useState(prevTab ? prevTab : '1');
    const [workoutList, setWorkoutList] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalPreset, setModalPreset] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [diet, setDiet] = useState("");
    const [dietList, setDietList] = useState([]);
    const [modalDiet, setModalDiet] = useState(false);
    const [modalDeleteTitle, setModalDeleteTitle] = useState("");
    const [modalDeleteSubtitle, setModalDeleteSubtitle] = useState("");
    const [modalDeleteText, setModalDeleteText] = useState("");
    const [modalDeleteId, setModalDeleteId] = useState("");
    const [loading, setLoading] = useState(true);
    const [isModalScheduleOpen, setIsModalScheduleOpen] = useState(false);
    const [dietInfo, setDietInfo] = useState({
        totalCalories: 0,
        totalProtein: 0,
        totalCarbs: 0,
        totalLipds: 0
    });
    const [modelAdded, setModelAdded] = useState(() => {
        const saved = localStorage.getItem('modelAdded');
        return saved ? JSON.parse(saved) : false;
    });

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleDeleteTraining = (id) => {
        RemoveTraining(access_token, id);
        setWorkoutList(workoutList.filter(training => training.id !== id));
        handleModalDelete();
    };

    const handleDeleteDiet = (id) => {
        RemoveDiet(access_token, id);
        setDietList(dietList.filter(diet => diet.id !== id));
        handleModalDelete();
    };

    const createNewItem = async (updated) => {
        window.dataLayer.push({
            'event': tab === '1' ? 'create_training' : 'create_diet',
            'client_id': client.id,
            'plan_id': client.plan.id,
            'plan_type': client.plan.plans?.name ?? 'Protocolo Demonstração'
        });

        if (tab === '1') {
            if (updated === true) {
                await fetchDataTraining(client.plan.id);
            }
            setModal(!modal);
        } else {
            setModalDiet(!modalDiet);
        }
    };

    const handlePreset = async (updated) => {
        if (tab === '1') {
            if (updated) {
                await fetchDataTraining(client.plan.id);
                if (isTutorial) {
                    setModelAdded(true);
                    localStorage.setItem('modelAdded', 'true');
                }
            }
            setModalPreset(!modalPreset);
        } else {
            if (updated) {
                await fetchDataDiets(client.plan.id);
            }
            setModalPreset(!modalPreset);
        }
    };

    const navigateTrainingDetails = (training) => {
        navigate('/manage-plan/workout-details', { state: { training: training, client: client, prevTab: '1', isTutorial } });
    };

    const navigateDietDetails = (diet) => {
        navigate('/manage-plan/diet-details', { state: { diet: diet, client: client, prevTab: '2' } });
    };

    const handleSetTraining = (e, training) => {
        e.stopPropagation();
        setModalDeleteTitle("Excluir treino");
        setModalDeleteSubtitle("Deseja excluir o treino:");
        setModalDeleteText(`Treino ${training.name}`);
        setModalDeleteId(training.id);
        handleModalDelete();
    };

    const handleSetDiet = (e, diet) => {
        e.stopPropagation();
        setModalDeleteTitle("Excluir refeição");
        setModalDeleteSubtitle("Deseja excluir a refeição:");
        setModalDeleteText(diet.name + ' - ' + diet.info.totalCalories + 'kcal');
        setModalDeleteId(diet.id);
        handleModalDelete();
    };

    const fetchDataTraining = async (id) => {
        const response = await GetTrainings(access_token, id);
        const trainings = response?.data;

        setWorkoutList(trainings);
        setLoading(false);
    };

    const fetchDataDiets = async (id) => {
        const response = await GetDiets(access_token, id);
        const diets = response?.data;

        setDietList(diets);
        setLoading(false);
    };

    const openModalSchedule = () => {
        setIsModalScheduleOpen(true);
    };

    useEffect(() => {
        fetchDataTraining(client.plan.id);
        fetchDataDiets(client.plan.id);
    }, [client.id]);

    useEffect(() => {
        let totalCalories = 0;
        let totalProtein = 0;
        let totalCarbs = 0;
        let totalLipds = 0;
        if (dietList) {
            dietList.forEach(d => {
                const { info } = d;
                totalCalories += info?.totalCalories ?? 0;
                totalProtein += info?.protein ?? 0;
                totalCarbs += info?.carbohydrates ?? 0;
                totalLipds += info?.lipids ?? 0;
            });
        }
        setDietInfo({
            totalCalories,
            totalProtein,
            totalCarbs,
            totalLipds
        });
    }, [dietList]);

    useEffect(() => {
        return () => {
            if (!isTutorial) {
                localStorage.removeItem('modelAdded');
            }
        };
    }, [isTutorial]);

    return (
        <div className="manage-client-plan">
            <PageWrapper menuItem="/manage-students">
                <Box className="content">
                    <StudentHeader client={client} />
                    <TabContext value={tab}>
                        <Box>
                            <Button
                                variant="outlined"
                                sx={{ height: 50, width: { xs: '100%', md: '23%' }, mb: 1 }}
                                onClick={openModalSchedule}
                                disabled={expiredPlan}
                            >Novo agendamento</Button>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="space-between"
                                spacing={2}
                                className="actions-container"
                            >
                                <TabList onChange={(e, newValue) => setTab(newValue)}>
                                    <Tab className={`tab-btn ${tab === '1' && 'active'}`} label="Treino" value="1" />
                                    <Tab className={`tab-btn ${tab === '2' && 'active'}`} label="Dieta" value="2" />
                                </TabList>
                                {isTutorial && !modelAdded ? (
                                    <TutorialOverlay text="Clique aqui para selecionar um modelo de treino">
                                        <Button
                                            variant="contained"
                                            sx={{
                                                fontWeight: 500,
                                                width: { xs: '100%', md: 250 },
                                                animation: 'pulse 2s infinite',
                                                position: 'relative',
                                                zIndex: 1001,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setModalPreset(true)}
                                        >
                                            Selecionar Modelo
                                        </Button>
                                    </TutorialOverlay>
                                ) : (
                                    <Button
                                        variant="contained"
                                        sx={{ fontWeight: 500, width: { xs: '100%', md: 250 } }}
                                        disabled={expiredPlan}
                                        onClick={() => setModalPreset(true)}
                                    >
                                        Selecionar Modelo
                                    </Button>
                                )
                                }
                            </Stack>
                        </Box>
                        <div className="manage-plan-container">
                            <div className="list-item">
                                <Stack
                                    display={{ xs: 'block', sm: 'block', md: 'flex' }}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div>
                                        <Typography fontSize={20} fontWeight={600}
                                            color="#1e306e">{tab === '1' ? 'Treino' : 'Resumo Dieta'}</Typography>
                                        <Typography fontSize={14}>
                                            {tab === '1' ? 'Veja os treinos do aluno abaixo' :
                                                <Box className="subtitle" sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                    <Chip label={`${dietInfo.totalProtein.toFixed(0)} PTN`}
                                                        variant="filled" color="success" size="small" />
                                                    <Chip label={`${dietInfo.totalCarbs.toFixed(0)} CHO`}
                                                        variant="filled" color="info" size="small" />
                                                    <Chip label={`${dietInfo.totalLipds.toFixed(0)} LIP`}
                                                        variant="filled" color="warning" size="small" />
                                                    <Chip label={`${dietInfo.totalCalories.toFixed(0)} KCAL`}
                                                        variant="filled" color="error" size="small" />
                                                </Box>}
                                        </Typography>
                                    </div>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        flexDirection={{ sx: 'column', sm: 'row' }}
                                        className="new-item-container"
                                    >
                                        <span>Protocolo:</span>
                                        <span
                                            className="student-info error">{client.plan.plans?.name ?? 'Protocolo Demonstração'}</span>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        flexDirection={{ sx: 'column', sm: 'row' }}
                                        className="new-item-container"
                                    >
                                        <span>Duração:</span>
                                        <span className="student-info error">{client.plan.weeks} semanas</span>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        flexDirection={{ sx: 'column', sm: 'row' }}
                                        className="new-item-container"
                                    >
                                        <span>Idade:</span>
                                        <span className="student-info error">{client.age} anos</span>
                                    </Stack>
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        flexDirection={{ sx: 'column', sm: 'column' }}
                                        className="new-item-container"
                                    >
                                        <Button disabled={expiredPlan} className="actions-btn"
                                            sx={{ color: '#FFF', fontWeight: 700, px: 2 }}
                                            onClick={!expiredPlan && createNewItem}>
                                            {tab === '1' ? 'Novo Treino' : 'Nova Refeição'}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </div>
                            <Box sx={{ my: 2, display: { xs: 'block', sm: 'none' } }}>
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        sx={{ fontWeight: 600 }}
                                    > {tab === '1' ? 'Volume de treino' : 'Calculadora energética'}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {tab === '1' && <VolumeChart client={client} />}
                                        {tab === '2' && <CaloriesCalculator
                                            client={client}
                                            totalCalories={dietInfo.totalCalories}
                                            protein={dietInfo.totalProtein}
                                            carbohydrates={dietInfo.totalCarbs}
                                            lipids={dietInfo.totalLipds}
                                        />}
                                    </AccordionDetails>
                                </Accordion>
                            </Box>

                            <TabPanel value="1" index={0} sx={{ mb: { xs: 3, sm: 0 } }}>
                                {isTutorial && modelAdded && (
                                    <TutorialOverlay text="Clique no treino para ver os detalhes">
                                        <Box>
                                            {workoutList && workoutList.map((training, key) => (
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    className={`list-item ${key % 2 === 0 && 'background-gray'}`}
                                                    key={key}
                                                    onClick={() => !training.restDay ? navigateTrainingDetails(training) : null}
                                                    sx={{
                                                        animation: 'pulse 2s infinite',
                                                        position: 'relative',
                                                        zIndex: 1001,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <div className="item-header">
                                                        <p className="title">{training.restDay ? training.name : `Treino ${training.name}`}</p>
                                                        <p className="subtitle">{`Dias do treino: ${training.weeks[0].days.map((d) => d.day)}`}</p>
                                                        <p className="subtitle">{`Semanas: ${training.weeks?.map((w) => ` ${w.week}`)}`}</p>
                                                    </div>

                                                    <div className="actions">
                                                        <IconButton edge="end" aria-label="delete"
                                                            onClick={(e) => handleSetTraining(e, training)}>
                                                            <DeleteIcon sx={{ color: '#c7427b' }} />
                                                        </IconButton>
                                                        {!training.restDay && <div className="edit">
                                                            <img src={APP_PATH + "icon-edit.svg"} alt="icon-edit" />
                                                            <span>Editar treino</span>
                                                        </div>}
                                                    </div>
                                                </Stack>
                                            ))}

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                mt: 2
                                            }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => navigate('/onboarding-success', { state: { step: 3 } })}
                                                >
                                                    Continuar Tour
                                                </Button>
                                            </Box>
                                        </Box>
                                    </TutorialOverlay>
                                )}

                                {(!isTutorial || !modelAdded) && (
                                    <>
                                        {workoutList && workoutList.map((training, key) => (
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                className={`list-item ${key % 2 === 0 && 'background-gray'}`}
                                                key={key}
                                                onClick={() => !training.restDay ? navigateTrainingDetails(training) : null}
                                            >
                                                <div className="item-header">
                                                    <p className="title">{training.restDay ? training.name : `Treino ${training.name}`}</p>
                                                    <p className="subtitle">{`Dias do treino: ${training.weeks[0].days.map((d) => d.day)}`}</p>
                                                    <p className="subtitle">{`Semanas: ${training.weeks?.map((w) => ` ${w.week}`)}`}</p>
                                                </div>

                                                <div className="actions">
                                                    <IconButton edge="end" aria-label="delete"
                                                        onClick={(e) => handleSetTraining(e, training)}>
                                                        <DeleteIcon sx={{ color: '#c7427b' }} />
                                                    </IconButton>
                                                    {!training.restDay && <div className="edit">
                                                        <img src={APP_PATH + "icon-edit.svg"} alt="icon-edit" />
                                                        <span>Editar treino</span>
                                                    </div>}
                                                </div>
                                            </Stack>
                                        ))}
                                    </>
                                )}

                                {loading && (<CommonLoading color="#553872" size="large" />)}
                                {((!workoutList || workoutList.length === 0) && !loading) && (
                                    <EmptyState
                                        title="Ainda não há nenhum treino criado!"
                                        subtitles={['Clique em criar novo treino e configure o primeiro', 'treino do seu aluno.']}
                                    />
                                )}
                            </TabPanel>
 
                            <TabPanel value="2" index={1} sx={{ mb: { xs: 3, sm: 0 } }}>
                                {dietList && dietList.map((diet, key) => (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        className={`list-item ${key % 2 === 0 && 'background-gray'}`}
                                        key={key}
                                        onClick={() => navigateDietDetails(diet)}
                                    >
                                        <div className="item-header">
                                            <Typography variant="subtitle1" fontWeight={700} fontSize={16}
                                                color="#1e306e">{diet.name}</Typography>

                                            <Box className="subtitle" sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                <Chip label={`${diet.info.protein.toFixed(0)} PTN`} variant="outlined"
                                                    color="success" size="small" />
                                                <Chip label={`${diet.info.carbohydrates.toFixed(0)} CHO`}
                                                    variant="outlined" color="info" size="small" />
                                                <Chip label={`${diet.info.lipids.toFixed(0)} LIP`} variant="outlined"
                                                    color="warning" size="small" />
                                                <Chip label={`${diet.info.fiber.toFixed(0)} FIB`} variant="outlined"
                                                    color="default" size="small" />
                                                <Chip label={`${diet.info.totalCalories.toFixed(0)} KCAL`}
                                                    variant="outlined" color="error" size="small" />
                                            </Box>
                                        </div>
                                        <div className="actions">
                                            <IconButton edge="end" aria-label="delete"
                                                onClick={(e) => handleSetDiet(e, diet)}>
                                                <DeleteIcon sx={{ color: '#c7427b' }} />
                                            </IconButton>
                                            <div className="edit" onClick={() => navigateDietDetails(diet)}>
                                                <img src={APP_PATH + "icon-edit.svg"} alt="icon-edit" />
                                                <span>Editar refeição</span>
                                            </div>
                                        </div>
                                    </Stack>
                                ))}
                                {loading && (<CommonLoading color="#553872" size="large" />)}
                                {((!dietList || dietList.length === 0) && !loading) && (
                                    <EmptyState
                                        title="Ainda não há nenhuma refeição criada!"
                                        subtitles={['Clique em adicionar refeição e configure a primeira', 'refeição do seu aluno.']} />
                                )}
                            </TabPanel>
                        </div>
                    </TabContext>

                    <ModalAddPreset
                        key={tab}
                        modal={modalPreset}
                        handleModal={handlePreset}
                        client={client}
                        type={tab === '1' ? 'training' : 'diet'}
                    />

                    <ModalAddTraining
                        modal={modal}
                        handleModal={createNewItem}
                        client={client}
                    />
                    <ModalAddDiet
                        modal={modalDiet}
                        handleModal={createNewItem}
                        dietList={dietList}
                        setDietList={setDietList}
                        diet={diet}
                        setDiet={setDiet}
                        client={client}
                        fetchDataDiets={fetchDataDiets}
                    />

                    <ModalAddSchedule
                        open={isModalScheduleOpen}
                        handleModal={() => setIsModalScheduleOpen(false)}
                        client={client}
                    />

                    {modalDelete && (
                        <ModalDelete
                            handleModal={handleModalDelete}
                            title={modalDeleteTitle}
                            subtitle={modalDeleteSubtitle}
                            text={modalDeleteText}
                            id={modalDeleteId}
                            handleDelete={tab === '1' ? handleDeleteTraining : handleDeleteDiet}
                        />
                    )}
                </Box>

                <Box sx={{
                    display: { xs: 'none', lg: 'flex' },
                    flexDirection: 'column',
                    bgcolor: '#F9FAFB',
                    gap: 1,
                    mr: 1,
                    mt: 1
                }}>
                    <AnswersCard client={client} />
                    {tab === '2' && <CaloriesCalculator
                        client={client}
                        totalCalories={dietInfo.totalCalories}
                        protein={dietInfo.totalProtein}
                        carbohydrates={dietInfo.totalCarbs}
                        lipids={dietInfo.totalLipds}
                    />}
                    {tab === '1' && <VolumeChart
                        client={client}
                    />}
                    <Evolution client={client} />
                </Box>

            </PageWrapper>
        </div>
    );
}

export default ManageClientPlan;