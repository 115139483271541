import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, } from '@mui/material';
import { Check, FileCopy, WhatsApp } from '@mui/icons-material';

const PaymentLink = ({ link, student }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(link).then(() => {
            setCopied(true);  // Define o estado como copiado
            setTimeout(() => setCopied(false), 5000); // Reverte para o ícone original após 5 segundos
        });
    };

    const getWhatsappLink = (e) => {
        e.stopPropagation();
        const breakLine = '%0A';
        const message = `Olá, ${student.name}!${breakLine}*Sua consultoria está a um passo de se tornar oficial!*${breakLine}${breakLine}Segue o link para pagamento: ${encodeURIComponent(link)}`;

        window.open(`https://wa.me/55${student.phone.replace(/\D/g, '')}?text=${message}`, '_blank');
    };

    return (
        <TextField
            value={link}
            disabled
            sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                    backgroundColor: '#f5f5f5',  // Cor de fundo do input
                    paddingRight: 5, // Espaço para o ícone do botão
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                            sx={{ bgcolor: '#FFF', px: 1, fontSize: 14, fontWeight: 600,  }}
                            onClick={handleCopy}
                        >
                            <IconButton
                                sx={{
                                    color: '#1976d2', // Cor do ícone
                                    '&:hover': {
                                        backgroundColor: '#e3f2fd', // Efeito hover
                                    }
                                }}
                            >
                                {copied ? <Check /> : <FileCopy />}
                            </IconButton>
                            {copied ? 'Copiado' : 'Copiar'}
                        </Box>
                        {student && <Box
                            sx={{ bgcolor: '#FFF', px: 1, fontSize: 14, fontWeight: 600,  }}
                            onClick={getWhatsappLink}
                        >
                            <IconButton
                                sx={{
                                    color: '#1976d2', // Cor do ícone
                                    '&:hover': {
                                        backgroundColor: '#e3f2fd', // Efeito hover
                                    }
                                }}
                            >
                                <WhatsApp sx={{ color: '#25D366' }} />
                            </IconButton>
                            Enviar
                        </Box>}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PaymentLink;