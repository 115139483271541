import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import TextArea from '../../../../Components/TextArea/TextArea';
import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import Feedback from '../../../../Components/Feedback/Feedback';
import { UpdatedTraining } from "../../../../Services/Trainings/index";

import './WorkoutInfo.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do treino é obrigatório!"),
    comments: yup.string(),
});

const WorkoutInfo = ({ training, planWeeks, planActivityId, presetId = null }) => {
    const weekDays = [
        {
            id: 0,
            label: 'Segunda-Feira',
            value: 'SEG',
            checked: false
        },
        {
            id: 1,
            label: 'Terça-Feira',
            value: 'TER',
            checked: false
        },
        {
            id: 2,
            label: 'Quarta-Feira',
            value: 'QUA',
            checked: false
        },
        {
            id: 3,
            label: 'Quinta-Feira',
            value: 'QUI',
            checked: false
        },
        {
            id: 4,
            label: 'Sexta-Feira',
            value: 'SEX',
            checked: false
        },
        {
            id: 5,
            label: 'Sábado',
            value: 'SAB',
            checked: false
        },
        {
            id: 6,
            label: 'Domingo',
            value: 'DOM',
            checked: false
        }
    ];
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [days, setDays] = useState(weekDays);
    const [weeks, setWeeks] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showDayError, setShowDayError] = useState(false);
    const [success, setSucess] = useState(false);
    const access_token = localStorage.getItem('access_token');

    const onSelectWeek = (week, checked) => {
        setWeeks((weeks) => {
            return weeks.map((w, i) => {
                if (w === week) return { ...w, checked: Boolean(checked) };
                return w;
            });
        });
    };

    const onSelectDay = (day, checked) => {
        setDays((days) => {
            return days.map((d, i) => {
                if (d === day) return { ...d, checked: Boolean(checked) };
                return d;
            });
        });
    };

    const onSubmitHandler = async (data) => {
        const selectedDays = days.filter((d) => d.checked);
        if ((!selectedDays || selectedDays.length === 0)) {
            setShowDayError(true);
            return;
        }
        const selectedWeeks = !presetId ? weeks.filter((w) => w.checked) : [{ value: 0 }];
        if ((!selectedWeeks || selectedWeeks.length === 0)) {
            setShowError(true);
            return;
        }

        const weeksPayload = selectedWeeks.map((w) => ({
            week: w.value,
            days: selectedDays.map((d) => ({ day: d.value, finished: false })),
        }));

        let updatedPayload = {
            id: training.id,
            name: data.name,
            weeks: weeksPayload,
            comments: data.comments || ''
        };

        if (presetId) {
            updatedPayload.preset = presetId;
        } else {
            updatedPayload.plansActivity = planActivityId;
        }

        await UpdatedTraining(access_token, updatedPayload);

        setSucess(true);
        setTimeout(() => {
            setSucess(false);
        }, 2500);
    };

    useEffect(() => {
        const weeksElem = [];
        for (let i = 0; i < planWeeks; i++) {
            weeksElem.push({
                label: i + 1,
                value: i + 1,
                checked: training.weeks.find((w) => w.week === i + 1)
            });
        }
        setWeeks(weeksElem);
    }, [planWeeks, training]);

    useEffect(() => {
        setDays((days) => (
            days.map((wd) => ({
                ...wd,
                checked: training.weeks[0].days.find((d) => d.day === wd.value) !== undefined
            }))
        ));
    }, [training]);

    useEffect(() => {
        if (training.id) {
            setValue('name', training.name);
            setValue('comments', training.comments);
        }
    }, [setValue, training]);

    return (
        <form id="form" className="workout-info">
            <Accordion>
                <AccordionSummary
                    expandIcon={<img src={process.env.REACT_APP_PUBLIC_URL + "icon-accordion.svg"}
                        alt={"icon-accordion"} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div>
                        <Title title="Informações básicas do treino" />
                        <Subtitle subtitle="Edite as informações abaixo" />
                    </div>
                </AccordionSummary>
                {success && (<Feedback severity="success" text="Informações do treino alteradas com sucesso" />)}

                <AccordionDetails>
                    <div className='accordion-container'>
                        <div className='accordion-item'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    name={'name'}
                                    label={'Insira o nome do treino'}
                                    defaultValue={training.name}
                                    fullWidth
                                    errors={errors.name}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='accordion-item'>
                        <div className='accordion-item'>
                            <Subtitle subtitle="Dia(s):" />
                            <FormGroup row className="days-form-group">
                                {days.map((day, i) => (
                                    <FormControlLabel style={{ margin: 0 }} key={day.label} control={
                                        <Checkbox
                                            name={day.label}
                                            value={day.value}
                                            id={day.value.toString()}
                                            checked={Boolean(day.checked)}
                                            onChange={(e) => onSelectDay(day, !day.checked)}
                                        />
                                    } label={day.label} />
                                ))}
                                {showDayError && <p className='error'>Selecione pelo menos um dia.</p>}
                            </FormGroup>
                        </div>
                        {presetId === null && <div className='accordion-container mt'>
                            <div className='accordion-item'>
                                <Subtitle subtitle="Semana(s):" />
                                <FormGroup className="weeks-form-group">
                                    {weeks.map((week, i) => (
                                        <FormControlLabel style={{ margin: 0 }} key={week.value} control={
                                            <Checkbox
                                                name={week.label.toString()}
                                                value={week.value}
                                                id={week.value.toString()}
                                                checked={Boolean(week.checked)}
                                                onChange={(e) => onSelectWeek(week, !week.checked)}
                                            />
                                        } label={week.label} />
                                    ))}
                                    {showError && <p className='error'>Selecione pelo menos uma semana.</p>}
                                </FormGroup>
                            </div>
                        </div>}
                    </div>
                    <div className='accordion-container'>
                        <div className='accordion-item'>
                            <Subtitle subtitle="Observação sobre o treino:" />
                            <div className='textarea'>
                                <TextArea
                                    control={control}
                                    name={'comments'}
                                    label={'Insira suas observações para o aluno sobre o treino'}
                                    defaultValue={training.comments}
                                    fullWidth
                                    errors={errors.comments}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='accordion-item'>
                        <div className='div-button'>
                            <PrimaryButton text="Salvar alterações" onClick={handleSubmit(onSubmitHandler)} />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </form>
    );
};

export default WorkoutInfo;