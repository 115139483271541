import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';
import { Delete, Edit } from "@mui/icons-material";

import Header from "../../Components/Header/Header";
import ModalDelete from "../../Components/ModalDelete/ModalDelete";
import { Box, Button, ButtonGroup, Chip, Typography } from "@mui/material";
import ModalAddPreset from "./Components/ModalAddPreset/ModalAddPreset";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DeletePreset, GetPresets } from "../../Services/Presets/index";

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import Feedback from '../../Components/Feedback/Feedback';
import TutorialOverlay from '../../Components/TutorialOverlay/TutorialOverlay';

import "./Presets.scss";

const Preset = () => {
    const PUBLIC_PATH = process.env.REACT_APP_PUBLIC_URL;
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const navigate = useNavigate();
    const location = useLocation();
    const isTutorial = location.search.includes('tutorial=true');
    const returnTo = location.state?.returnTo;
    const stepId = location.state?.stepId;
    const { type } = useParams();

    const [presets, setPresets] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [preset, setPreset] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const getMaxPeriodizations = () => {
        if (!consultant.plan) {
            return 1;
        }

        if (consultant.plan.name === 'Basic') {
            return 3;
        } else if (consultant.plan.name === 'Pro') {
            return 15;
        }
    };

    const handleModal = () => {
        setModal(!modal);
    };

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleSetPreset = (e, preset) => {
        e.stopPropagation();
        setPreset(preset);
        handleModalDelete();
    };

    const handleDeletePreset = async (id) => {
        try {
            await deletePreset(id)
            const newList = presets.filter(p => p.id !== id);
            setPresets(newList);

            // GTM - Delete Preset Success
            window.dataLayer.push({
                'event': 'delete_preset_success',
                'preset_id': id,
                'consultant_id': consultant.id
            });
        } catch (ex) {
            // GTM - Delete Preset Error
            window.dataLayer.push({
                'event': 'delete_preset_error',
                'preset_id': id,
                'error_message': ex?.error || 'Erro ao remover preset.',
                'consultant_id': consultant.id
            });

            setError(ex?.error || 'Erro ao remover preset.');
            setTimeout(() => {
                setError(undefined);
            }, 10000);
        }
        finally {
            handleModalDelete();
        }
    };

    const deletePreset = async (id) => {
        await DeletePreset(id);
    };

    const handleTutorialComplete = useCallback((preset) => {
        if (isTutorial && returnTo) {
            navigate(`/manage-preset/${type}/${preset.id}?tutorial=true`, {
                state: {
                    preset: preset,
                    tutorial: true,
                    returnTo,
                    stepId
                },
            });
        }
    }, [isTutorial, returnTo, stepId, navigate, type]);

    const fetchData = useCallback(async () => {
        const presetList = await GetPresets(type);
        setPresets(presetList?.data?.rows || []);

        if (isTutorial && presetList?.data?.rows.length > 0) {
            handleTutorialComplete(presetList?.data?.rows[0]);
        }

        // GTM - View Presets List
        window.dataLayer.push({
            'event': 'view_presets_list',
            'presets_count': presetList?.data?.rows?.length || 0,
            'consultant_id': consultant.id,
            'preset_type': type
        });

        setLoading(false);
    }, [type, consultant.id, handleTutorialComplete, isTutorial]);

    const handleNewPreset = () => {
        handleModal();
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (isTutorial) {
            setModal(true);
        }
    }, [isTutorial]);

    const renderNewPresetButton = (withTutorial = false) => (
        <Button
            variant="contained"
            className="new-preset-btn"
            onClick={handleNewPreset}
            startIcon={<AddCircleOutlineIcon />}
            sx={{
                ...(withTutorial && {
                    animation: 'pulse 2s infinite',
                }),
            }}
        >Novo Modelo
        </Button>
    );

    return (
        <div className="preset">
            <PageWrapper menuItem="/preset">
                <div className="content">
                    <Header
                        title={type === 'training' ? 'Modelos de treino' : 'Modelos de dieta'}
                        subtitle={type === 'training' ? 'Veja os modelos de treino cadastrados abaixo' : 'Veja os modelos de dieta cadastrados abaixo'}
                    />

                    {error && (<Feedback severity="error" text={error} />)}
                    {loading && (<CommonLoading color="#553872" size="large" />)}
                    {!loading && (
                        <div className="preset-container">
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 3,
                                p: 2,
                            }}>
                                {isTutorial ? (
                                    <TutorialOverlay text="Crie seu primeiro treino modelo">
                                        {renderNewPresetButton(true)}
                                    </TutorialOverlay>
                                ) : (
                                    renderNewPresetButton()
                                )}
                                <Chip
                                    label={<Typography>{`${presets.length}/${getMaxPeriodizations()} Modelos`}</Typography>}
                                    color="primary"
                                    variant="outlined"
                                />
                                {presets.length === getMaxPeriodizations() && <Typography>Você chegou no limite de modelos.
                                    <Link to="/upgrade" variant="body2">
                                        Clique aqui para atualizar seu plano.
                                    </Link>
                                </Typography>}
                            </Box>
                            {presets.length > 0 && (
                                presets.map((preset, key) => (
                                    <Box key={key}
                                        className={`preset-item ${key % 2 === 0 && 'background-gray'}`}
                                        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}
                                        onClick={() => navigate(`/manage-preset/${type}/${preset.id}`, { state: { preset: preset } })}>
                                        <div>
                                            <p className="title">{preset.name}</p>
                                        </div>

                                        <div className="actions">
                                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                                <Button component="label" startIcon={<Edit />} size="small" onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(`/manage-preset/${type}/${preset.id}`, { state: { preset: preset } })
                                                }}>
                                                    Editar
                                                </Button>
                                                <Button component="label" onClick={(e) => handleSetPreset(e, preset)}
                                                    startIcon={<Delete />} size="small">
                                                    Apagar
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Box>
                                ))
                            )}
                            {presets.length === 0 && (
                                <section className="empty-list">
                                    <div>
                                        <div className="images-container">
                                            <img src={PUBLIC_PATH + "group-top.svg"} alt="group-top" className="image-top" />
                                            <img src={PUBLIC_PATH + "group-quite.svg"} alt="group-quite" className="image-quite" />
                                            <img src={PUBLIC_PATH + "group-bottom.svg"} alt="group-bottom" className="image-bottom" />
                                        </div>
                                        <h4>Ainda não há nenhum modelo cadastrado!</h4>
                                        <div>
                                            <p>Clique em criar novo modelo e configure seu primeiro</p>
                                            <p>modelo de {type === 'training' ? 'treino' : 'dieta'}.</p>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    )}

                    <ModalAddPreset
                        handleModal={handleModal}
                        presets={presets}
                        setPresets={setPresets}
                        reloadPresets={fetchData}
                        open={modal}
                        setModal={setModal}
                        type={type}
                    />
                    {modalDelete && (
                        <ModalDelete
                            handleModal={handleModalDelete}
                            title="Excluir modelo"
                            subtitle="Deseja excluir o modelo salvo:"
                            text={`${preset.name} ?`}
                            id={preset.id}
                            handleDelete={handleDeletePreset}
                        />
                    )}
                </div>
            </PageWrapper>
        </div>
    );
}

export default Preset;