import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModalQuiz from '../ModalQuiz/ModalQuiz';

const Questionnaires = ({ student, handleUpdate }) => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);

    return (
        <>
            <Card variant="outlined" className="questionnaires" sx={{
                maxHeight: { xs: '380px', md: '250px' },
                overflowY: 'scroll',
                scrollbarColor: 'grey white',
                padding: 0,

            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 3,
                    pl: 4,
                    py: 1,
                    pb: 0,
                    mb: 0
                }}>
                    <Typography sx={{ fontSize: '15px', fontWeight: 600, color: '#553872' }}
                        onClick={() => setModal(true)}>Questionários</Typography>

                    <IconButton onClick={() => setModal(true)}>
                        <AddCircleIcon style={{ color: '#553872', fontSize: 28 }} />
                    </IconButton>
                </Box>
                <List sx={{
                    px: 3
                }}>
                    {student && student.questionnaire.map((questionare, key) => (
                        <div key={key}>
                            <ListItemButton
                                sx={{ padding: 0 }}
                                onClick={() => navigate('/manage-plan/questionnaires', {
                                    state: {
                                        id: questionare.id,
                                        client: student,
                                        questionnaire: questionare
                                    }
                                })}
                            >
                                <ListItem
                                    alignItems="center"
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete">
                                            <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                                        </IconButton>
                                    }>
                                    <ListItemText
                                        primary={questionare.quizzes.name}
                                        secondary={
                                            <Box>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color={questionare.isAnswered ? "#3ac49c" : "red"}
                                                    sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
                                                >
                                                    {questionare.isAnswered ? <>
                                                        <CheckCircleOutlineOutlinedIcon />
                                                        Respondido
                                                    </> : <>
                                                        <CancelOutlinedIcon />
                                                        Não Respondido
                                                    </>
                                                    }
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </ListItem>
                            </ListItemButton>
                            {key !== student.questionnaire.length - 1 && <Divider variant="fullWidth" component="div" />}
                        </div>
                    ))}
                </List>
            </Card>
            <ModalQuiz modal={modal} setModal={setModal} student={student} handleModal={handleUpdate} />
        </>
    );
};

export default Questionnaires;