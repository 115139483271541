import React from 'react';
import { Box, Button, Typography, } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import Grid from '@mui/material/Grid2';
import Avatar from '@mui/material/Avatar';

const PersonalInfo = ({ student, show }) => {
    const breakLine = '%0A';
    const getWhatsappLink = () => {
        const appStore = 'https://apps.apple.com/br/app/train-me/id6482847649';
        const playStore = 'https://play.google.com/store/apps/details?id=com.trainme.app';
        const message = `Olá, ${student.name}!${breakLine}*Seu acesso à TrainMe está liberado!*${breakLine}${breakLine}Tá na hora de mudar o nível dos seus treinos!${breakLine}${breakLine}Para acessar seus treinos siga as instruções abaixo:${breakLine}Instale o APP na loja do seu dispositivo:${breakLine}${breakLine}*IOS:* ${appStore}${breakLine}*Android:* ${playStore}${breakLine}${breakLine}O sistema irá enviar o acesso para o seu e-mail: ${student.email}${student.tempPassword !== null ? `, caso não tenha recebido, segue sua senha provisória: *${student.tempPassword}*` : ''}.${breakLine}${breakLine}_Qualquer dúvida, estou à disposição!_`;

        return `https://wa.me/55${student.phone.replace(/\D/g, '')}?text=${message}`;
    };

    return (
        <Box className="personal-info" sx={{
            bgcolor: 'background.paper',
            
            px: 2,
            py: 2,
            gap: 1,
            width: '100%',
            height: { xs: '90%', md: 'auto', lg: 'auto' }        }}>
            {student && show && (
                <><Grid container spacing={2}>
                    <Grid container xs={12} spacing={1}>
                        <Grid item xs={2}>
                            <Avatar
                                fit="contain"
                                src={student.profileImagePath}
                                alt={student.name} />
                        </Grid>
                        <Grid container xs={2}>
                            <AccountCircleOutlinedIcon />
                            <Typography variant="body1" sx={{ lineBreak: 'anywhere' }}>{student.name}</Typography>
                        </Grid>
                        <Grid container xs={2}>
                            <EmailIcon />
                            <Typography variant="body1" sx={{ lineBreak: 'anywhere' }}>{student.email}</Typography>
                        </Grid>
                        <Grid container xs={2}>
                            {student.gender === 'M' && <>
                                <MaleIcon />
                                <Typography variant="body1">Masculino</Typography>
                            </>}
                            {student.gender === 'F' && <>
                                <FemaleIcon />
                                <Typography variant="body1">Feminino</Typography>
                            </>}
                        </Grid>
                        <Grid container xs={2}>
                            <PhoneIcon />
                            <a rel="noreferrer" target="_blank"
                                href={`https://wa.me/55${student.phone.replace(/\D/g, '')}`}
                            >
                                <Typography variant="body1">
                                    {student.phone}
                                </Typography>
                            </a>
                        </Grid>
                        <Grid container xs={2}>
                            <LockOpenIcon />
                            <Typography
                                variant="body1">{`Senha provisória: ${student.tempPassword ?? '-'}`}</Typography>
                        </Grid>
                        <Grid container xs={2}>
                            <Button
                                variant="outlined"
                                color="success"
                                href={getWhatsappLink()}
                                target="_blank"
                            >
                                Enviar Boas Vindas
                            </Button>
                        </Grid>
                    </Grid>
                </Grid></>
            )}
            {!student && show && (
                <Typography variant="h3">Você não possui alunos</Typography>
            )}
        </Box>
    );
};

export default PersonalInfo;