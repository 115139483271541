import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {
    Alert,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Link,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from '@mui/material';
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {cpf} from "cpf-cnpj-validator";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PersonIcon from '@mui/icons-material/Person';

import PrimaryButton from '../../Components/PrimaryButton/PrimaryButton';
import QuestionBox from './components/QuestionBox/QuestionBox';
import PaymentSelection from './components/PaymentSelection/PaymentSelection';

import {GetPlan, GetProtocolPayment} from "../../Services/Plans";
import {CreateStudent, GetStudent, GetStudentById} from "../../Services/Students";
import {CheckLimit} from "../../Services/Consultants";

import './NewStudentForm.scss';

const phoneRegExp = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;
const schemaPersonalData = yup.object().shape({
    name: yup.string().required("O campo de nome é obrigatório!"),
    height: yup.string().required("O campo de altura é obrigatório!"),
    birthDate: yup.string()
        .required("O campo de data de nascimento é obrigatório!")
        .nullable()
        .test('birthDate', 'Data inválida', (value) => {
            var dateParts = value.split("/");
            if (Number(dateParts[0]) > 31) return false;
            if (Number(dateParts[1]) > 12) return false;
            if (Number(dateParts[2]) > new Date().getFullYear() || Number(dateParts[2]) < new Date().getFullYear() - 100) return false;
            return true;
        }),
    phone: yup.string()
        .required("O campo de telefone é obrigatório!")
        .matches(phoneRegExp, 'Celular inválido, formato correto: (11) 12345-6789'),
    cpf: yup.string()
        .required("O campo de CPF é obrigatório!")
        .max(14, 'Número máximo de digitos atingido')
        .test('cpf', 'CPF Inválido', (value) => cpf.isValid(value.replaceAll('.', '').replaceAll('-', ''))),
    email: yup.string()
        .required("O campo de email é obrigatório!")
        .email('Formato de email incorreto!'),
});

const NewStudentForm = () => {
    const STEPS = {
        PERSONAL_DATA: 0,
        PAYMENT: 1,
        // QUESTIONS: 2, DEPRECATED
        FEEDBACK: 3,
        ERROR: 4,
    };
    const {control, handleSubmit, formState: {errors}, setValue, reset, getValues} = useForm({
        resolver: yupResolver(schemaPersonalData),
    });
    const [searchParams] = useSearchParams();

    /* Id do protocolo */
    const planId = searchParams.get('pid');
    /* Id do consultor */
    const consultantId = searchParams.get('cid');
    /* Id do estudante */
    const studentId = searchParams.get('sid');
    /* Id do protocolo */
    const plansActivityId = searchParams.get('paid');
    /* Nome do consultor */
    const consultantName = searchParams.get('cn');
    /* Flag do webview Mobile */
    const isMobile = searchParams.get('mobile');

    const boxStyle = {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        
    };

    const [currentStep, setCurrentStep] = useState(STEPS.PERSONAL_DATA);
    const [planInfo, setPlanInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(true);
    const [gender, setGender] = useState('M');
    const [terms, setTerms] = useState(false);
    const [termsMessage, setTermsMessage] = useState('');
    const [personalData, setPersonalData] = useState();
    const [error, setError] = useState();
    const [onlyEmail, setOnlyEmail] = useState(true);

    const nextStep = async (data) => {
        try {
            if (!terms) {
                setTermsMessage('É necessário aceitar os termos de uso da plataforma.');
                return;
            }
            setLoading(true);
            await createStudent(data);
        } catch (ex) {
            console.log(ex);
        } finally {
            setLoading(false);
        }
    };

    const createStudent = async (data) => {
        const dateParts = data.birthDate.split("/");
        const birth = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

        const payload = {
            cid: consultantId,
            name: data.name,
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            height: Number(data.height.replace('cm', '')),
            document: data.cpf.replaceAll('.', '').replaceAll('-', ''),
            birthDate: birth ?? data.birthDate
        };
        const response = await CreateStudent(payload);
        setCurrentStep(STEPS.PAYMENT);
        setPersonalData({...data, id: response.data.id});
    };

    const finishQuestionnaire = async (id, data, creditCard = false) => {
        setLoading(true);

        try {
            setCurrentStep(STEPS.FEEDBACK);

            // if (isMobile) {
            //     setTimeout(() => {
            //         window.location.replace('www.trainme.com.br');
            //     }, 5000);
            // }
        } catch (ex) {
            console.log(ex);
            alert('Ocorreu um erro ao enviar seu protocolo.');
        } finally {
            setLoading(false);
        }
    };

    const getActiveStudents = async () => {
        setLoading(true);
        const email = getValues("email");
        const response = await GetStudent(email);

        if (response.data && response.status) {
            setValue('email', email);
            setValue('name', response.data.name);
            setValue('phone', response.data.phone.toString());
            setValue('gender', response.data.gender);
            setValue('height', response.data.height ? response.data.height.toString() : '0');
            setValue('cpf', response.data.document);
            setGender(response.data.gender);

            const date = new Date(response.data.birthDate);
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const year = date.getUTCFullYear();
            const formattedDate = `${day}/${month}/${year}`;
            setValue('birthDate', formattedDate);
            handleSubmit(nextStep)();
        } else {
            setOnlyEmail(false);
            reset();
            setValue('email', email);
        }
        setLoading(false);
    };

    useEffect(() => {
        // Validar se o consultor já atingiu o máximo de alunos

        const loadPlanInfo = async () => {
            try {
                const planData = await GetPlan(planId);
                setPlanInfo(planData.data);
            } catch (ex) {
                console.log(ex);
                setError(ex?.response?.data?.error ?? 'Ocorreu um erro ao carregar a tela de pagamentos.');
                setCurrentStep(STEPS.ERROR);
            }
        };

        const initialize = async () => {
            try {
                if (!studentId) {
                    await CheckLimit(consultantId);
                }
                await loadPlanInfo();

                if (studentId && plansActivityId) {
                    await getProtocolPayment();
                } else if (studentId) {
                    await getStudent();
                } else {
                    setCurrentStep(STEPS.PERSONAL_DATA);
                }
            } catch (ex) {
                console.log(ex);
                setError(ex?.response?.data?.error ?? 'Ocorreu um erro ao carregar a tela de pagamentos.');
                setCurrentStep(STEPS.ERROR);
            } finally {
                setLoadingScreen(false);
            }
        };

        const getStudent = async () => {
            const response = await GetStudentById(studentId);
            if (response && response.data) {
                setPersonalData({
                    id: response.data.id,
                    birthDate: response.data.birthDate,
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone.toString(),
                    gender: response.data.gender,
                    height: response.data.height ? response.data.height.toString() : '0',
                    cpf: response.data.document
                });
                setCurrentStep(STEPS.PAYMENT);
            }
        };

        const getProtocolPayment = async () => {
            const response = await GetProtocolPayment(plansActivityId);
            if (response?.data?.status === 'approved') {
                setError('Esse protocolo já foi pago.');
                setCurrentStep(STEPS.ERROR);
            } else {
                await getStudent();
            }
        };

        initialize();
    }, [STEPS.PERSONAL_DATA, STEPS.PAYMENT, STEPS.ERROR, studentId, planId, plansActivityId, consultantId]);

    return (
        <Box className="new-student-form">
            {loadingScreen && (<>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    flexDirection="column"
                >
                    <CircularProgress size={60}/>
                    <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{mt: 2}}
                    >
                        Carregando...
                    </Typography>
                </Box>
            </>)}
            {!loadingScreen && (<>
                {planInfo && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                            mt: 2,
                        }}>
                        <Box sx={{
                            backgroundColor: '#FFF',
                            px: 3,
                            py: 4,
                            width: {xl: '35%', lg: '50%', xs: '90%'},
                        }}>
                            <Typography component="h1" fontSize={16} variant="subtitle2" color="black">
                                {consultantName}
                            </Typography>
                            <Typography component="h2" fontSize={14} variant="subtitle2">
                                {planInfo.name}
                            </Typography>
                            <Typography component="h2" variant="subtitle2">
                                {planInfo.description}
                            </Typography>
                            <Divider sx={{my: 2}}/>
                            <Typography component="h2" variant="subtitle2">
                                <Box sx={{display: 'flex', gap: 1}}>
                                    <Chip color="success" label={Number(planInfo.price).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })} variant="filled"/>
                                    <Chip label={`${planInfo.weeks} semanas`} variant="outlined" color="info"/>
                                </Box>
                            </Typography>
                        </Box>
                    </Box>
                )}
                {currentStep === STEPS.PERSONAL_DATA && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Box
                            component="form"
                            noValidate
                            sx={{
                                mt: 1,
                                width: {xl: '35%', lg: '50%', xs: '90%'},
                                backgroundColor: '#FFF',
                                p: 2,
                            }}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                                mb={2}
                            >
                                <PersonIcon/>
                                <Typography fontSize={24}>Informações Pessoais</Typography>
                            </Stack>
                            <QuestionBox
                                control={control}
                                name="email"
                                question="E-mail"
                                type="text"
                                onBlur={() => setOnlyEmail(true)}
                                errorMessage={errors.email}
                            />
                            {!onlyEmail && (<>
                                <Box sx={{display: {lg: 'flex', sm: 'block'}, gap: 3, my: 2}}>
                                    <QuestionBox
                                        control={control}
                                        name="name"
                                        question="Nome Completo"
                                        type="text"
                                        errorMessage={errors.name}
                                    />
                                    <QuestionBox
                                        control={control}
                                        name="phone"
                                        question="Celular"
                                        type="text"
                                        mask="(00) 00000-0000"
                                        errorMessage={errors.phone}
                                    />
                                </Box>

                                <Box sx={{display: {lg: 'flex', sm: 'block'}, gap: 3, my: 2}}>
                                    <QuestionBox
                                        control={control}
                                        name="cpf"
                                        question="CPF"
                                        type="text"
                                        mask="000.000.000-00"
                                        errorMessage={errors.cpf}
                                    />
                                    <QuestionBox
                                        control={control}
                                        name="birthDate"
                                        question="Data de nascimento"
                                        type="text"
                                        mask="00/00/0000"
                                        errorMessage={errors.birthDate}
                                    />
                                </Box>
                                <Box sx={{display: {lg: 'flex', sm: 'block'}, gap: 3, my: 2}}>
                                    <QuestionBox
                                        control={control}
                                        name="height"
                                        question="Altura"
                                        type="text"
                                        mask="000cm"
                                        errorMessage={errors.height}
                                    />

                                    <Box sx={boxStyle}>
                                        <FormControl>
                                            <FormLabel id="radio-buttons-group-label">
                                                <Typography component="h3" variant="subtitle1" color="#000"
                                                            fontWeight="600" sx={{mb: 1}}>
                                                    Gênero
                                                </Typography>
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="radio-buttons-group-label"
                                                defaultValue="M"
                                                name="radio-buttons-group"
                                                value={gender}
                                                onChange={(e) => setGender(e.target.value)}
                                            >
                                                <Box
                                                    sx={{display: 'flex'}}
                                                >

                                                    <FormControlLabel value="M" control={<Radio/>} label="Masculino"/>
                                                    <FormControlLabel value="F" control={<Radio/>} label="Feminino"/>
                                                </Box>
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </>)}
                            <Box>
                                <FormControlLabel style={{margin: 0, padding: 0, color: "white"}} control={
                                    <Checkbox
                                        label="ESTOU CIENTE DOS TERMOS DE USO E POLÍTICA DE PRIVACIDADE."
                                        onChange={() => setTerms(!terms)}
                                        checked={terms}
                                        href="https://www.coach.trainme.com.br/privacy-police"
                                    />
                                } label={
                                    <p style={{color: 'black', fontSize: 14}}>
                                        ESTOU CIENTE DOS &nbsp;
                                        <Link
                                            style={{textDecoration: "none"}}
                                            target="_blank"
                                            href="https://www.coach.trainme.com.br/privacy-police"
                                        >TERMOS DE USO</Link> &nbsp; E &nbsp;
                                        <Link
                                            style={{textDecoration: "none"}}
                                            target="_blank"
                                            href="https://www.coach.trainme.com.br/privacy-police"
                                        >
                                            POLÍTICAS DE PRIVACIDADE.
                                        </Link>
                                    </p>
                                }
                                />
                                {termsMessage && <p style={{color: '#db3d60'}}>{termsMessage}</p>}
                            </Box>

                            <Alert severity="info" sx={{mt: 2}}>
                                Os dados informados serão utilizados para criar sua conta no aplicativo, confirme
                                novamente e em seguida prossiga para o pagamento.
                            </Alert>
                            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                {onlyEmail && <PrimaryButton
                                    text="Próximo"
                                    loading={loading}
                                    onClick={getActiveStudents}
                                />}
                                {!onlyEmail && <PrimaryButton
                                    text={!onlyEmail ? "Criar conta" : "Ir para o pagamento"}
                                    loading={loading}
                                    onClick={handleSubmit(nextStep)}
                                />}
                            </Box>
                        </Box>
                    </Box>
                )}

                {currentStep === STEPS.PAYMENT && (
                    <PaymentSelection
                        personalData={personalData}
                        consultantName={consultantName}
                        planInfo={planInfo}
                        consultantId={consultantId}
                        gender={gender}
                        callback={finishQuestionnaire}
                        plansActivityId={plansActivityId}
                        isMobile={isMobile}
                    />
                )}

                {currentStep === STEPS.FEEDBACK && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        {isMobile && (<>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: 'black',
                                    marginTop: '15px',
                                    padding: {xs: '10px', sm: '10px'},
                                    marginBottom: '15px'
                                }}
                            >
                                Seu pagamento foi finalizado com sucesso, em breve você terá seu novo protocolo.
                            </Typography>
                        </>)}
                        {!isMobile && (<>
                            <Typography
                                variant="h6"
                                sx={{color: 'black', marginTop: '15px', padding: {xs: '10px', sm: '10px'}}}
                            >
                                Parabéns, você está no caminho para alcançar o corpo e a saúde que deseja!
                            </Typography>
                            <Typography
                                variant="subtitle"
                                sx={{color: 'black', padding: {xs: '10px', sm: '10px'}}}
                            >
                                Basta entrar em nosso aplicativo e responder o questionário do consultor.
                            </Typography>
                            <Typography
                                variant="subtitle"
                                sx={{color: 'black', padding: {xs: '10px', sm: '10px'}}}
                            >
                                Você receberá um email com as informações para entrar em nosso aplicativo, caso não
                                encontre o email, verifique sua caixa de spam.
                            </Typography>
                            <Stack sx={{display: "flex", flexDirection: "row", gap: 5, mt: 5, marginBottom: '15px'}}>
                                <a href="https://play.google.com/apps/internaltest/4700496584994904970" target="_blank"
                                   rel="noreferrer">
                                    <img
                                        src="https://www.svgrepo.com/show/223032/playstore.svg"
                                        alt="Baixe na Play Store"
                                        width={50}
                                        height={50}
                                    />
                                </a>
                                <a href="https://apps.apple.com/br/app/train-me/id6482847649" target="_blank"
                                   rel="noreferrer">
                                    <img
                                        src="https://www.svgrepo.com/show/353415/apple-app-store.svg"
                                        alt="Baixe na App Store"
                                        width={50}
                                        height={50}
                                    />
                                </a>
                            </Stack>
                        </>)}
                        <img
                            width={800}
                            height={500}
                            className="final"
                            src="https://saude.sesisc.org.br/wp-content/uploads/sites/13/2023/09/Beneficios-de-fazer-academia-Para-sua-saude-e-seu-corpo-scaled.jpg"
                            alt="imagem academia"
                        />
                    </Box>
                )}

                {currentStep === STEPS.ERROR && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                marginTop: '15px',
                                padding: {xs: '10px', sm: '10px'},
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2
                            }}
                        >
                            <ErrorOutlineIcon sx={{fontSize: 48, color: 'red'}}/>
                            Tivemos um problema na geração do seu pagamento
                        </Typography>
                        <Typography
                            variant="subtitle"
                            sx={{fontWeight: 'bold', padding: {xs: '10px', sm: '10px'}}}
                        >
                            {error}
                        </Typography>
                    </Box>
                )}
            </>)}
        </Box>
    );
};

export default NewStudentForm;