import React, { useState, useRef, useEffect } from 'react';
import {
    Autocomplete,
    Box,
    IconButton,
    TextField as MuiTextField,
} from '@mui/material';
import Compressor from 'compressorjs';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhotoCamera } from '@mui/icons-material';

import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import TextArea from '../../../../Components/TextArea/TextArea';

import { UpdateProfilePic } from '../../../../Services/Consultants';

const schema = yup.object().shape({
    credentialType: yup.string(),
    credential: yup.string(),
    occupation: yup.string(),
    description: yup.string(),
});
const DadosCadastrais = ({ currentSubStep, onDataChange }) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const { control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: consultant
    });

    const hiddenFileInput = useRef(null);
    const [file, setFile] = useState();
    const [typeRegistry, setTypeRegistry] = useState(consultant?.credentialType || 'CREF');
    
    // Recuperar dados do sessionStorage se existirem
    const savedFormValues = sessionStorage.getItem('dados_cadastrais_form_values');
    const initialFormValues = savedFormValues ? JSON.parse(savedFormValues) : {
        credentialType: consultant?.credentialType || 'CREF',
        credential: consultant?.credential || '',
        occupation: consultant?.occupation || '',
        description: consultant?.description || '',
        profilePicture: consultant?.profilePicture || ''
    };

    const [formValues, setFormValues] = useState(initialFormValues);

    const handleFormChange = (field, value) => {
        const newValues = {
            ...formValues,
            [field]: value
        };
        setFormValues(newValues);
        // Salvar no sessionStorage para persistir entre mudanças de step
        sessionStorage.setItem('dados_cadastrais_form_values', JSON.stringify(newValues));
        onDataChange(newValues, true);
        setValue(field, value);
    };

    // Enviar dados atuais quando mudar o step
    useEffect(() => {
        onDataChange(formValues, true);
    }, [currentSubStep]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeProfilePicture = (e) => {
        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.5,
            success: async (compressedResult) => {
                const access_token = localStorage.getItem('access_token');
                const newProfile = URL.createObjectURL(compressedResult);
                setFile(newProfile);
                await UpdateProfilePic(access_token, consultant.id, compressedResult);
                
                const newValues = {...formValues, profilePicture: newProfile};
                setFormValues(newValues);
                sessionStorage.setItem('dados_cadastrais_form_values', JSON.stringify(newValues));
                onDataChange(newValues, true);

                window.dataLayer.push({
                    'event': 'onboarding_success_click',
                    'button_name': 'atualizar_foto',
                    'consultant_id': consultant?.id
                });
            },
        });
    };

    // Carregar foto do perfil salva
    useEffect(() => {
        if (formValues.profilePicture) {
            setFile(formValues.profilePicture);
        }
    }, [formValues.profilePicture]);

    const renderStepContent = () => {
        switch (currentSubStep) {
            case 0:
                return (
                    <Box p={1}>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
                            <Autocomplete
                                name="typeCredential"
                                disableClearable
                                options={["CREF", "CRN", "CRM"]}
                                renderInput={(params) => <MuiTextField {...params} label="Conselho" />}
                                value={typeRegistry}
                                onChange={(_, data) => {
                                    setTypeRegistry(data);
                                    handleFormChange('credentialType', data);
                                }}
                                defaultValue={typeRegistry}
                                sx={{ width: '200px' }}
                            />
                            <TextFieldTrainMe
                                control={control}
                                name="credential"
                                label="Registro"
                                fullWidth
                                errors={errors.credential}
                                onChange={(e) => handleFormChange('credential', e.target.value)}
                                helperText={formValues.credential?.trim().length === 0 ? "Campo obrigatório" : ""}
                            />
                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <Box p={1}>
                        <TextFieldTrainMe
                            control={control}
                            name="occupation"
                            label="Ocupação"
                            fullWidth
                            errors={errors.occupation}
                            onChange={(e) => handleFormChange('occupation', e.target.value)}
                        />
                    </Box>
                );
            case 2:
                return (
                    <Box p={1}>
                        <TextArea
                            control={control}
                            name="description"
                            label="Sua descrição"
                            fullWidth
                            errors={errors.description}
                            onChange={(e) => handleFormChange('description', e.target.value)}
                        />
                    </Box>
                );
            case 3:
                return (
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 3
                    }}>
                        <IconButton
                            onClick={() => hiddenFileInput.current.click()}
                            color="info"
                            aria-label="upload picture"
                            component="span"
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                {(!file) && <img
                                    src="https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg"
                                    alt="avatar"
                                    height={200}
                                    width={200}
                                />}
                                {file && <img
                                    src={file}
                                    alt="avatar"
                                    height={300}
                                    width="100%"
                                />}

                                <div>
                                    <PhotoCamera />
                                    <input
                                        type="file"
                                        name="myImage"
                                        ref={hiddenFileInput}
                                        style={{ display: 'none' }}
                                        onChange={handleChangeProfilePicture}
                                    />
                                </div>
                            </Box>
                        </IconButton>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto' }}>
            {renderStepContent()}
        </Box>
    );
};

export default DadosCadastrais; 