import React from 'react';
import {IMaskInput} from 'react-imask';
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material';


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
        <IMaskInput
            {...other}
            mask={props.mask}
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});


const CustomInput = ({label, name, type, mask, errors, register, onChange, disabled = false, value = undefined}) => {

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                required
                fullWidth
                id={name}
                label={label}
                name={name}
                type={type}
                onChange={onChange}
                disabled={disabled}
                value={value}
                inputProps={{
                    mask: mask,
                    ...register(name, {
                        required: `O campo de ${label} é obrigatório.`,
                    }),
                }}
                inputComponent={TextMaskCustom}
            />
            {errors && <FormHelperText style={{color: 'red'}}>{errors.message}</FormHelperText>}
        </FormControl>
    );
};

export default CustomInput;