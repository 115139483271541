import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';
import { Stack, Typography, Button, ButtonGroup, Box, Chip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Delete, Edit } from "@mui/icons-material";

import Header from "../../Components/Header/Header";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import ModalDelete from "../../Components/ModalDelete/ModalDelete";
import EmptyState from "../../Components/EmptyState/EmptyState";
import ModalAddDiet from "../ManageClientPlan/Components/ModalAddDiet/ModalAddDiet";
import ModalAddTraining from "../ManageClientPlan/Components/ModalAddTraining/ModalAddTraining"
import VolumeChart from "../../Components/VolumeChart/VolumeChart";
import TutorialOverlay from "../../Components/TutorialOverlay/TutorialOverlay";
import CaloriesCalculator from "../../Components/CaloriesCalculator/CaloriesCalculator";

import { GetTrainingsPreset, RemoveTraining } from "../../Services/Trainings/index";
import { CreateExercise } from "../../Services/Exercises";
import { GetDietsPreset, RemoveDiet } from "../../Services/Diets/index";

import "./ManagePresets.scss";

const ManagePresets = () => {
    const access_token = localStorage.getItem('access_token');

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isTutorial = location.search.includes('tutorial=true');
    const { type } = useParams();

    const [workoutList, setWorkoutList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalTraining, setModalTraining] = useState(false);
    const [modalDiet, setModalDiet] = useState(false);
    const [presetId,] = useState(id);
    const [client,] = useState({ plan: { id: null, weeks: 1 }, presetId });
    const [modalDelete, setModalDelete] = useState(false);
    const [modalDeleteTitle, setModalDeleteTitle] = useState("");
    const [modalDeleteSubtitle, setModalDeleteSubtitle] = useState("");
    const [modalDeleteText, setModalDeleteText] = useState("");
    const [modalDeleteId, setModalDeleteId] = useState("");
    const [showTutorialMessage, setShowTutorialMessage] = useState(false);
    const returnTo = location.state?.returnTo;
    const stepId = location.state?.stepId;
    const [dietList, setDietList] = useState([]);
    const [diet, setDiet] = useState("");
    const [dietInfo, setDietInfo] = useState({
        totalCalories: 0,
        totalProtein: 0,
        totalCarbs: 0,
        totalLipds: 0
    });

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleDeleteTraining = (id) => {
        RemoveTraining(access_token, id);
        setWorkoutList(workoutList.filter(training => training.id !== id));

        // GTM - Delete Training
        window.dataLayer.push({
            'event': 'delete_training',
            'training_id': id,
            'preset_id': presetId
        });

        handleModalDelete();
    };

    const handleDeleteDiet = (id) => {
        RemoveDiet(access_token, id);
        setDietList(dietList.filter(diet => diet.id !== id));

        // GTM - Delete Training
        window.dataLayer.push({
            'event': 'delete_diet',
            'diet_id': id,
            'preset_id': presetId
        });
        handleModalDelete();
    };

    const createNewTraining = async (updated) => {
        if (updated === true) {
            await fetchDataTraining(presetId);
            if (isTutorial) {
                // Cria exercício padrão para o tutorial
                await createDefaultExercise();
                setShowTutorialMessage(true);
            }

            // GTM - Create Training Success
            window.dataLayer.push({
                'event': 'create_training_success',
                'preset_id': presetId,
                'is_tutorial': isTutorial
            });
        }
        setModalTraining(!modalTraining);
    };

    const createNewDiet = async (updated) => {
        if (updated === true) {
            await fetchDataDiet(presetId);

            // GTM - Create Training Success
            window.dataLayer.push({
                'event': 'create_diet_success',
                'preset_id': presetId
            });
        }
        setModalDiet(!modalDiet);
    };

    const createDefaultExercise = async () => {
        try {
            const defaultExercise = {
                name: "Rosca Martelo com Halteres",
                repetitions: "12",
                series: "4",
                executionMethod: "Padrão",
                rest: "60",
                cadency: "1:1",
                observations: "",
                order: 1,
                training: workoutList[workoutList.length - 1].id, // ID do treino recém criado
                group: { id: 1 },
                exerciseLibrary: 238,
                spareExercise: undefined,
                linkedExercises: []
            };

            const response = await CreateExercise(access_token, defaultExercise);

            // GTM - Tutorial Default Exercise Created
            window.dataLayer.push({
                'event': 'tutorial_default_exercise_created',
                'exercise_name': defaultExercise.name,
                'training_id': workoutList[workoutList.length - 1].id,
                'preset_id': presetId
            });

            return response;
        } catch (error) {
            console.error('Erro ao criar exercício padrão:', error);
        }
    };

    const handleSetTraining = (e, training) => {
        e.stopPropagation();
        setModalDeleteTitle("Excluir treino");
        setModalDeleteSubtitle("Deseja excluir o treino:");
        setModalDeleteText(`Treino ${training.name}`);
        setModalDeleteId(training.id);
        handleModalDelete();
    };

    const handleSetDiet = (e, diet) => {
        e.stopPropagation();
        setModalDeleteTitle("Excluir refeição");
        setModalDeleteSubtitle("Deseja excluir a refeição:");
        setModalDeleteText(diet.name + ' - ' + diet.info.totalCalories + 'kcal');
        setModalDeleteId(diet.id);
        handleModalDelete();
    };

    const fetchDataTraining = async (id) => {
        const response = await GetTrainingsPreset(access_token, id);
        const trainings = response?.data;
        setWorkoutList(trainings);
        setLoading(false);

        // GTM - View Preset Trainings
        window.dataLayer.push({
            'event': 'view_preset_trainings',
            'preset_id': id,
            'trainings_count': trainings?.length || 0
        });
    };

    const fetchDataDiet = async (id) => {
        const response = await GetDietsPreset(access_token, id);
        const diets = response?.data;
        setDietList(diets);
        setLoading(false);

        let totalCalories = 0;
        let totalProtein = 0;
        let totalCarbs = 0;
        let totalLipds = 0;
        if (diets) {
            diets.forEach(d => {
                const { info } = d;
                totalCalories += info?.totalCalories ?? 0;
                totalProtein += info?.protein ?? 0;
                totalCarbs += info?.carbohydrates ?? 0;
                totalLipds += info?.lipids ?? 0;
            });
        }
        setDietInfo({
            totalCalories,
            totalProtein,
            totalCarbs,
            totalLipds
        });
        // GTM - View Preset Trainings
        window.dataLayer.push({
            'event': 'view_preset_diets',
            'preset_id': id,
            'trainings_count': diets?.length || 0
        });
    };

    const navigateTrainingDetails = (training) => {
        const length = training.weeks.length
        navigate('/manage-plan/workout-details', { state: { training: training, client: { plan: { id: null, weeks: length }, presetId }, prevTab: '1' } });
    };

    const navigateDietDetails = (diet) => {
        navigate('/manage-plan/diet-details', { state: { diet: diet, client: client, presetId, prevTab: '2' } });
    };

    const handleContinue = () => {
        if (returnTo && stepId) {
            // GTM - Tutorial Continue From Preset
            window.dataLayer.push({
                'event': 'tutorial_continue_from_preset',
                'preset_id': presetId,
                'step_id': stepId
            });

            navigate(returnTo, {
                state: {
                    step: stepId
                }
            });
        }
    };

    useEffect(() => {
        if (type === 'training') {
            fetchDataTraining(presetId);
        } else {
            fetchDataDiet(presetId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderNewTrainingButton = (withTutorial = false) => (
        <Button
            variant="contained"
            className="new-student-btn"
            aria-label="Novo treino"
            onClick={() => type === 'training' ? createNewTraining(withTutorial) : createNewDiet(withTutorial)}
            startIcon={<AddCircleOutlineIcon />}
            sx={{
                ...(withTutorial && {
                    animation: 'pulse 2s infinite',
                }),
            }}
        >{type === 'training' ? 'Novo Treino' : 'Nova Refeição'}
        </Button>
    );

    return (client &&
        <div className="training-list">
            <PageWrapper menuItem={type === 'training' ? "/preset/training" : "/preset/diet"}>
                <div className="content">
                    <Header
                        title={type === 'training' ? 'SmartTrainer' : 'SmartNutri'}
                        subtitle="Crie sua biblioteca de periodizações"
                        showReturn
                    />

                    {showTutorialMessage && (
                        <TutorialOverlay text="">
                            <Box
                                sx={{
                                    bgcolor: 'rgba(102, 45, 145, 0.05)',
                                    borderRadius: 2,
                                    border: '1px solid rgba(102, 45, 145, 0.1)',
                                    mb: 1,
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 2,
                                    position: 'relative',
                                    zIndex: 1001,
                                    maxWidth: 600,
                                    mx: 'auto'
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#fff',
                                        textAlign: 'center',
                                        fontSize: '1.1rem',
                                        fontWeight: 500,
                                        lineHeight: 1.5,
                                        position: 'relative',
                                        zIndex: 1001
                                    }}
                                >
                                    Um treino modelo foi criado com exercícios básicos apenas para teste.
                                    Você pode editar ou excluir este treino a qualquer momento e criar novos
                                    treinos modelos.
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={handleContinue}
                                    sx={{
                                        bgcolor: '#662d91',
                                        '&:hover': {
                                            bgcolor: '#553872'
                                        },
                                        animation: 'pulse 2s infinite',
                                        position: 'relative',
                                        zIndex: 1001,
                                        fontSize: '1rem',
                                        py: 1.5,
                                        px: 4
                                    }}
                                >
                                    Continuar Tour
                                </Button>
                            </Box>
                        </TutorialOverlay>
                    )}

                    <div className="list-item">
                        {
                            isTutorial && !showTutorialMessage ? (
                                <TutorialOverlay text="">
                                    {renderNewTrainingButton(true)}
                                </TutorialOverlay>
                            ) : (
                                renderNewTrainingButton(false)
                            )
                        }
                    </div >


                    <Box sx={{
                        display: { xs: 'flex', md: 'grid' },
                        flexDirection: { xs: 'column', md: 'row' },
                        gridTemplateColumns: { xs: '100%', md: '40% 59%' },
                        gap: 2
                    }}>
                        <Box sx={{
                            bgcolor: 'background.paper',
                        }}>
                            {(type === 'training' && (!workoutList || workoutList.length === 0) && !loading) && (
                                <EmptyState
                                    title="Ainda não há nenhum treino criado!"
                                    subtitles={['Clique em criar novo treino e configure o primeiro', 'treino do seu aluno.']}
                                />
                            )}
                            {(type === 'diet' && (!dietList || dietList.length === 0) && !loading) && (
                                <EmptyState
                                    title="Ainda não há nenhuma refeição criado!"
                                    subtitles={['Clique em criar nova refeição e configure o primeiro', 'refeição do seu aluno.']}
                                />
                            )}

                            {workoutList.length > 0 && workoutList.map((training, key) => (
                                <Stack
                                    key={key}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}
                                    className={`list-item ${key % 2 === 0 && 'background-gray'}`}
                                    onClick={() => !training.restDay ? navigateTrainingDetails(training) : null}
                                >
                                    <div className="item-header">
                                        <p className="title">{training.restDay ? training.name : `Treino ${training.name}`}</p>
                                        <Typography className="subtitle">{`Dia(s): ${training.weeks[0].days.map((d) => d.day)}`}</Typography>
                                    </div>

                                    <div className="actions">
                                        <ButtonGroup variant="outlined">
                                            {!training.restDay &&
                                                <Button component="label" startIcon={<Edit />} size="small" onClick={(e) => { e.stopPropagation(); navigateTrainingDetails(training) }}>
                                                    Editar
                                                </Button>
                                            }
                                            <Button component="label" onClick={(e) => handleSetTraining(e, training)}
                                                startIcon={<Delete />} size="small">
                                                Apagar
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Stack>
                            ))}
                            {type === 'diet' && (
                                dietList && dietList.map((diet) => (
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                        alignItems={{ xs: 'flex-start', md: 'center' }}
                                        justifyContent="space-between"
                                        className={`list-item ${diet.id % 2 === 0 && 'background-gray'}`}
                                        key={diet.id}
                                        onClick={() => navigateDietDetails(diet)}
                                        sx={{ gap: 2, border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: 1, marginBottom: 1, backgroundColor: 'white' }}
                                    >
                                        <div className="item-header">
                                            <Typography variant="subtitle1" fontWeight={700} fontSize={16}
                                                color="#1e306e">{diet.name}</Typography>
                                            <Typography fontSize={14}>
                                                <Box className="subtitle" sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                                    <Chip label={`${diet.info.protein.toFixed(0)} PTN`} variant="outlined"
                                                        color="success" size="small" />
                                                    <Chip label={`${diet.info.carbohydrates.toFixed(0)} CHO`}
                                                        variant="outlined" color="info" size="small" />
                                                    <Chip label={`${diet.info.lipids.toFixed(0)} LIP`} variant="outlined"
                                                        color="warning" size="small" />
                                                    <Chip label={`${diet.info.fiber.toFixed(0)} FIB`} variant="outlined"
                                                        color="default" size="small" />
                                                    <Chip label={`${diet.info.totalCalories.toFixed(0)} KCAL`}
                                                        variant="outlined" color="error" size="small" />
                                                </Box>
                                            </Typography>
                                        </div>
                                        <Box>
                                            <ButtonGroup variant="outlined">
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                    <Button component="label" startIcon={<Edit />} size="small" onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigateDietDetails(diet);
                                                    }}>
                                                        Editar
                                                    </Button>
                                                    <Button component="label" onClick={(e) => handleSetDiet(e, diet)}
                                                        startIcon={<Delete />} size="small">
                                                        Apagar
                                                    </Button>
                                                </Box>
                                            </ButtonGroup>
                                        </Box>
                                    </Stack>
                                )))
                            }
                        </Box>

                        <Box>
                            {
                                type === 'training' &&
                                <VolumeChart
                                    client={client}
                                    presetId={presetId}
                                />
                            }
                            {
                                type === 'diet' &&
                                <CaloriesCalculator
                                    totalCalories={dietInfo.totalCalories}
                                    protein={dietInfo.totalProtein}
                                    carbohydrates={dietInfo.totalCarbs}
                                    lipids={dietInfo.totalLipds}
                                />
                            }
                        </Box>
                    </Box >

                    {loading && (<CommonLoading color="#553872" size="large" />)}

                    <ModalAddTraining
                        modal={modalTraining}
                        handleModal={createNewTraining}
                        client={client}
                    />

                    <ModalAddDiet
                        modal={modalDiet}
                        handleModal={createNewDiet}
                        dietList={dietList}
                        setDietList={setDietList}
                        diet={diet}
                        setDiet={setDiet}
                        client={client}
                        fetchDataDiets={fetchDataDiet}
                    />

                    {
                        modalDelete && (
                            <ModalDelete
                                handleModal={handleModalDelete}
                                title={modalDeleteTitle}
                                subtitle={modalDeleteSubtitle}
                                text={modalDeleteText}
                                id={modalDeleteId}
                                handleDelete={type === 'training' ? handleDeleteTraining : handleDeleteDiet}
                            />
                        )
                    }
                </div >
            </PageWrapper >
        </div >
    );
}

export default ManagePresets;