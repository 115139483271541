import React from 'react';
import SideMenu from '../SideMenu/SideMenu';
import WhatsAppSupport from '../WhatsAppSupport/WhatsAppSupport';

import './PageWrapper.scss';

const PageWrapper = ({ children, menuItem }) => {
    return (
        <div className="page-wrapper">
            <div className="container">
                <div className="side-menu-container">
                    <SideMenu menuItem={menuItem} />
                </div>

                {children}
            </div>
            <WhatsAppSupport />
        </div>
    );
};

export default PageWrapper;