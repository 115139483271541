import React from 'react';
import { Box, Typography } from '@mui/material';

const TutorialOverlay = ({ children, text, position = 'bottom' }) => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 1000,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 1001,
        }}
      >
        {children}
        <Typography
          sx={{
            color: 'white',
            position: 'absolute',
            [position]: -60,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '350px',
            textAlign: 'center',
            fontSize: '1.1rem',
            fontWeight: 500,
            wordWrap: 'break-word'
          }}
        >
          {text}
        </Typography>
      </Box>
    </>
  );
};

export default TutorialOverlay; 