import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Divider,
    Chip,
    Stack
} from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PaymentLink from '../../../../../../Components/PaymentLink/PaymentLink';

const PlanPreview = ({ planData }) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    };

    const getActivitiesChips = () => {
        const chips = [];
        if (planData.training) {
            chips.push(
                <Chip
                    key="training"
                    icon={<FitnessCenterIcon />}
                    label="Treinos"
                    color="primary"
                    variant="outlined"
                />
            );
        }
        if (planData.diet) {
            chips.push(
                <Chip
                    key="diet"
                    icon={<RestaurantIcon />}
                    label="Dieta"
                    color="primary"
                    variant="outlined"
                />
            );
        }
        if (planData.inLoco) {
            chips.push(
                <Chip
                    key="inLoco"
                    icon={<DirectionsRunIcon />}
                    label="Treino Presencial"
                    color="primary"
                    variant="outlined"
                />
            );
        }
        return chips;
    };

    return (
        <Card sx={{ width: '100%', maxWidth: 600, mx: 'auto', mt: 3 }}>
            <CardContent>
                <Typography variant="h5" gutterBottom color="primary">
                    Plano Criado
                </Typography>
                
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        {planData.planName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph>
                        {planData.description}
                    </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Stack spacing={2}>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                            Duração
                        </Typography>
                        <Typography variant="body1">
                            {planData.duration} semanas
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                            Valor
                        </Typography>
                        <Typography variant="body1">
                            {formatCurrency(planData.price)}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Atividades Incluídas
                        </Typography>
                        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                            {getActivitiesChips()}
                        </Stack>
                    </Box>
                </Stack>

                <Divider sx={{ my: 2 }} />

                <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Link de Pagamento
                    </Typography>
                    <PaymentLink link={planData.paymentLink} />
                </Box>
            </CardContent>
        </Card>
    );
};

export default PlanPreview; 