import React from 'react';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Tooltip from '@mui/material/Tooltip';

const WhatsAppSupport = () => {
    const handleClick = () => {
        const phoneNumber = '551151968694';
        const message = 'Olá, estou precisando de ajuda na plataforma da TrainMe!';
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <Tooltip title="Precisa de ajuda? Fale conosco no WhatsApp!" arrow>
            <IconButton
                color="primary"
                className="whatsapp-support"
                aria-label="WhatsApp Support"
                onClick={handleClick}
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    backgroundColor: '#25D366',
                    color: 'white'
                }}
            >
                <WhatsAppIcon style={{fontSize: 40}}/>
            </IconButton>
        </Tooltip>
    );
};

export default WhatsAppSupport;
