import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';

import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';

import {DeleteSchedule} from "../../../../Services/Schedules/index";

import './People.scss';

const People = ({id, name, schedule, description, past, callback, student}) => {
    const [, setModal] = useState(false);

    const deleteSchedule = async () => {
        const access_token = localStorage.getItem('access_token')
        await DeleteSchedule(access_token, id);
        await callback();
    };

    return (
        <>
            <div className={`people ${past ? 'past' : ''}`} onClick={() => setModal(true)}>
                <div className="people-left">
                    {(!past && !student) && <AccessTimeIcon color="secondary"/>}
                    {(!past && student) &&
                        <img
                            width={30}
                            height={30}
                            src={student.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${student.profileImagePath}` : 'https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg'}
                            alt="Imagem do aluno"
                        />
                    }
                    {past && <CheckIcon color="success"/>}
                    <div>
                        <p>{student ? `${student.name} | ${name}` : name}</p>
                        <span>{description}</span>
                        {past ? <span>{schedule}</span> : <strong>{schedule}</strong>}
                    </div>
                </div>

                <IconButton onClick={() => deleteSchedule()}>
                    <DeleteIcon/>
                </IconButton>

            </div>

            {/* {modal && student && <ModalPlans modal={modal} setModal={setModal} student={student} />} */}
        </>
    );
}

export default People;