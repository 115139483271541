import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import RocketIcon from '@mui/icons-material/Rocket';

import { GetPlans } from '../../../../Services/CoachPlans/index';

const ConsultantPlans = ({ callback, method }) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const [plans, setPlans] = useState([]);

    useEffect(() => {
        const load = async () => {
            const response = await GetPlans();
            setPlans(response.data);
        };

        load();
    }, []);

    const handlePlanSelection = (plan) => {
        // Evento do GTM para seleção de plano
        window.dataLayer.push({
            'event': 'plan_selected',
            'plan_name': plan.name,
            'plan_price': method === 'M' ? plan.monthlyValue : plan.anualValue,
            'plan_period': method === 'M' ? 'monthly' : 'yearly',
            'plan_max_clients': plan.maxClients || 'unlimited'
        });
        
        callback(2, plan);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                flexGrow: 1
            }}
        >
            <Grid container spacing={4}>
                {plans.map((plan, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                            variant="outlined"
                            sx={{
                                backgroundColor: plan.recommended ? '#f6f5f5' : 'white',
                                border: plan.recommended && '1px solid #553872',
                                height: '100%',
                                flexGrow: 1,
                            }}
                        >
                            {plan.recommended &&
                                <Typography
                                    sx={{
                                        backgroundColor: '#553872',
                                        color: '#FFF',
                                        textAlign: 'center',
                                        border: '2px solid #553872',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '100%',
                                        width: 'calc(100% + 2px)',
                                        left: '-4px',
                                        py: '4px'
                                    }}>
                                    Recomendado
                                </Typography>
                            }
                            <CardContent
                                sx={{
                                    mt: 2,
                                    transform: plan.recommended && 'translateY(-7%)',
                                    flexGrow: 1,
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 3
                                        }}>
                                        {index === 0 && <AttachMoneyIcon sx={{ color: '#553872', fontSize: 52 }} />}
                                        {index === 1 && <SignalCellularAltIcon sx={{ color: '#553872', fontSize: 52 }} />}
                                        {index === 2 && <RocketIcon sx={{ color: '#553872', fontSize: 52 }} />}
                                        <Typography
                                            variant="h6"
                                            component="h3"
                                            gutterBottom
                                            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                        > {plan.name}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {((plan.monthlyValue * 12).toFixed(2) !== plan.anualValue) && plan.monthlyValue !== '0.00' && <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: method === 'A' && plan.monthlyValue !== '0.00' ? 16 : 32,
                                                        lineHeight: '100%',
                                                        color: '#553872',
                                                        textDecoration: method === 'A' && plan.monthlyValue !== '0.00' ? 'line-through' : ''
                                                    }}
                                                    gutterBottom
                                                > {plan.monthlyValue === '0.00' ? 'Gratuito' : `R$ ${method === 'M' ? plan.monthlyValue : (plan.monthlyValue * 12).toFixed(2)}`}
                                                </Typography>}
                                                {plan.monthlyValue !== '0.00' && ((plan.monthlyValue * 12).toFixed(2) !== plan.anualValue) &&
                                                    <Typography
                                                        sx={{
                                                            textDecoration: method === 'A' ? 'line-through' : '',
                                                            fontSize: method === 'M' ? 16 : 12
                                                        }}>/{method === 'A' ? 'Ano' : 'Mês'}</Typography>
                                                }
                                            </Box>
                                            {method === 'A' && plan.monthlyValue !== '0.00' &&
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            fontSize: 32,
                                                            lineHeight: '100%',
                                                            color: '#553872'
                                                        }}
                                                        gutterBottom
                                                    > R$ {plan.anualValue}
                                                    </Typography>
                                                    <Typography>/Ano</Typography>
                                                </Box>
                                            }
                                            {method === 'A' && plan.monthlyValue !== '0.00' && ((plan.monthlyValue * 12).toFixed(2) !== plan.anualValue) &&
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontWeight: '600',
                                                            fontSize: 12,
                                                            color: '#553872'
                                                        }}
                                                        gutterBottom
                                                    > Economize:
                                                        R$ {((plan.monthlyValue * 12) - plan.anualValue).toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            }
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                            }}>
                                            <Typography
                                                sx={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}
                                                variant="subtitle2"
                                                component="strong"
                                                gutterBottom
                                            > {!plan.maxClients ? 'Alunos Ilimitados' : `Até ${plan.maxClients} alunos`}
                                            </Typography>
                                            <Typography
                                                sx={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}
                                                variant="subtitle2"
                                                component="strong"
                                                gutterBottom
                                            > {plan.name === 'Free' ? '1 modelo SmartTrainer' : plan.name === 'Pro' ? '15 modelos SmartTrainer' : '3 modelos SmartTrainer'}
                                            </Typography>
                                            <Typography
                                                sx={{ textAlign: 'center' }}
                                                variant="subtitle2"
                                                component="strong"
                                                gutterBottom
                                            >
                                                {plan.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                            {plan.monthlyValue === '0.00' && !consultant.plan &&
                                <CardActions
                                    sx={{
                                        justifyContent: 'flex-end',
                                        transform: plan.recommended && 'translateY(-35%)',
                                    }}>
                                    <Typography
                                        style={{
                                            width: '100%',
                                            fontWeight: 700,
                                            marginTop: 20,
                                            color: '#553872',
                                            textAlign: 'center'
                                        }}> Plano Atual
                                    </Typography>
                                </CardActions>
                            }

                            {plan.monthlyValue !== '0.00' &&
                                <CardActions
                                    sx={{
                                        justifyContent: 'flex-end',
                                        transform: plan.recommended && 'translateY(-35%)',
                                    }}>
                                    {consultant.plan && consultant.plan.id === plan.id && consultant.plan.packageType === method &&
                                        <Typography
                                            style={{
                                                width: '100%',
                                                fontWeight: 700,
                                                marginTop: 20,
                                                color: '#553872',
                                                textAlign: 'center'
                                            }}> Plano Contratado
                                        </Typography>}
                                    {(!consultant.plan || consultant.plan?.id !== plan.id || consultant.plan?.packageType !== method) &&
                                        <Button
                                            onClick={() => handlePlanSelection(plan)}
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                width: '100%',
                                                fontWeight: 600,
                                            }}> Quero esse plano
                                        </Button>}
                                </CardActions>
                            }
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ConsultantPlans;