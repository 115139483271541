import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";

import Header from "../../Components/Header/Header";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import CashSummary from "./components/CashSummary/CashSummary";
import Transactions from "./components/Transactions/Transactions";

import { GetTransactions } from '../../Services/Payments/index';

import "./Wallet.scss";

const Wallet = () => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const [totalRows, setTotalRows] = useState(0);
    const [bigNumbers, setBigNumbers] = useState();
    const [transactions, setTransactions] = useState();
    const [loading, setLoading] = useState(true);

    const getTransactions = async () => {
        const response = await GetTransactions(1, 5);

        if (response && response.data) {
            setTotalRows(response.data.count);
            setBigNumbers(response.data.bigNumbers);
            setTransactions(response.data.transactions);
        }
        setLoading(false);
    };

    useEffect(() => {
        getTransactions();
    }, []);

    return (
        <div className="wallet">
            <PageWrapper menuItem="/wallet">

                <div className="content">

                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12 }}>
                            <Header
                                title="Minha Carteira"
                                subtitle="Todas suas transações serão exibidas aqui na TrainMe Wallet"
                                name={consultant.name}
                                occupation={consultant.occupation}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }}>
                        {!loading && bigNumbers && <CashSummary
                                    availableAmount={bigNumbers.availableProfit}
                                    pendingAmount={bigNumbers.pendingWallet}
                                    totalManual={bigNumbers.totalManual}
                                    totalWallet={bigNumbers.totalWallet}
                                    total={bigNumbers.total}
                                />}
                                {loading && <>
                                    <Skeleton animation="wave" height={600} width="100%" />
                                </>}
                        </Grid>
                        <Grid size={{ xs: 12, md: 8 }}>
                        {!loading && transactions && <Transactions
                                    transactions={transactions}
                                    totalRows={totalRows}
                                />}
                                {loading && <>
                                    <Skeleton animation="wave" height={600} width="100%" />
                                </>}
                        </Grid>
                      
                    </Grid>
                </div>
            </PageWrapper>
        </div>
    );
}

export default Wallet;